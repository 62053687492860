import React, { useState } from "react";
import axios from "axios";
import SearchResults from "../Components/SearchResults";
import {
  OfficeRoles,
  Categories,
  Continents,
  Countries,
  Phases,
} from "../FilterOptionsConstants";

import {
  Form,
  Row,
  Col,
  Input,
  Button,
  Select,
  Tooltip,
  DatePicker,
  InputNumber,
  Collapse,
  Card,
  Checkbox,
} from "antd";
import { Option } from "antd/lib/mentions";

export const SearchScreenMR = () => {
  const [expand, setExpand] = useState(false);
  const [form] = Form.useForm();
  const [selectedContinent, setSelectedContinent] = useState(null);
  const [customCountryList, setCustomCountryList] = useState(
    Countries.map((element) => {
      return element.Country;
    }).sort()
  );
  const [tableData, setTableData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const populateOptions = (optionsArray, listType = null) => {
    let options = [];
    if (listType === "Countries") {
      //filter out countries for the continent in state
      if (selectedContinent) {
        optionsArray = optionsArray.filter((element) => {
          return element.Continent === selectedContinent;
        });
      }

      optionsArray = optionsArray.map((element) => {
        return element.Country;
      });

      optionsArray.sort();
    }
    optionsArray.forEach((element, index) => {
      options.push(<Option key={element}>{element}</Option>);
    });

    return options;
  };

  const populateYears = () => {
    const century = 20;
    let options = [];
    for (let year = 20; year < 100; year++) {
      options.push(
        <Option key={`${century}${year}`}>{`${century}${year}`}</Option>
      );
    }
    return options;
  };
  const formFields = [
    {
      name: "projectId",
      toolTip: "Project ID",
      node: <Input placeholder="Project ID" style={{ width: 180 }} />,
    },
    {
      name: "projectName",
      toolTip: "Project Name",
      node: <Input placeholder="Project Name" style={{ width: 180 }} />,
    },
    {
      name: "continent",
      toolTip: "Region",
      node: (
        <Select
          allowClear
          placeholder="Continent"
          onChange={(value, option) => {
            console.log(value);
            setSelectedContinent(value);
          }}
        >
          {populateOptions(Continents)}
        </Select>
      ),
    },
    {
      name: "countryList",
      toolTip: "Country",
      node: (
        <Select
          placeholder="Countries"
          mode="multiple"
          onChange={(value) => {
            console.log(value);
            setCustomCountryList(value);
          }}
        >
          {populateOptions(Countries, "Countries")}
        </Select>
      ),
    },
    {
      name: "phaseList",
      toolTip: "Phase",
      node: (
        <Select placeholder="Phase" mode="multiple">
          {populateOptions(Phases)}
        </Select>
      ),
    },
    {
      name: "openingDateList",
      toolTip: "Opening Date",
      // node: (
      //   <DatePicker
      //     placeholder="Opening Date"
      //     picker="year"
      //     mode="multiple"
      //     style={{ width: 180 }}
      //     onChange={(value) => {
      //       console.log(value);
      //     }}
      //   ></DatePicker>
      // ),
      node: (
        <Select placeholder="Opening Date" mode="multiple">
          {populateYears()}
        </Select>
      ),
    },
    {
      name: "categoryList",
      toolTip: "Category",
      node: (
        <Select placeholder="Category" mode="multiple">
          {populateOptions(Categories)}
        </Select>
      ),
    },
    {
      name: "starsSearch",
      toolTip: "Stars",
      node: (
        <Checkbox.Group
          options={[
            { label: "4-Star", value: 4 },
            { label: "5-Star", value: 5 },
          ]}
        ></Checkbox.Group>
      ),
    },
    {
      name: "roomsMin",
      toolTip: "Rooms (Min.)",
      node: <Input placeholder="Rooms (Min.)" style={{ width: 180 }} />,
    },
    {
      name: "roomsMax",
      toolTip: "Rooms (Max.)",
      node: <Input placeholder="Rooms (Max.)" style={{ width: 180 }} />,
    },
    {
      name: "tbiMin",
      toolTip: "Investment Min. (In Mil $)",
      node: (
        <InputNumber
          placeholder="Investment Min. (In Mil $)"
          style={{ width: 180 }}
        />
      ),
    },
    {
      name: "tbiMAx",
      toolTip: "Investment Max. (In Mil $)",
      node: (
        <InputNumber
          placeholder="Investment Max. (In Mil $)"
          style={{ width: 180 }}
        />
      ),
    },

    {
      name: "hardBrand",
      toolTip: "Hard Brand",
      node: <Input placeholder="Hard Brand" style={{ width: 180 }} />,
    },
    {
      name: "softBrands",
      toolTip: "Soft Brand",
      node: <Input placeholder="Soft Brand" style={{ width: 180 }} />,
    },
    {
      name: "officeRolesList",
      toolTip: "Role",
      node: (
        <Select placeholder="Role" mode="multiple">
          {populateOptions(OfficeRoles)}
        </Select>
      ),
    },
    {
      name: "officeCompany",
      toolTip: "Company",
      node: <Input placeholder="Company" style={{ width: 180 }} />,
    },
    {
      name: "officeContactFirstName",
      toolTip: "First Name",
      node: <Input placeholder="First Name" style={{ width: 180 }} />,
    },
    {
      name: "officeContactLastName",
      toolTip: "Last Name",
      node: <Input placeholder="Last Name" style={{ width: 180 }} />,
    },
  ];
  const { Panel } = Collapse;

  const getFields = () => {
    const count = expand ? 20 : 14;
    const children = [];

    formFields.forEach((element, i) => {
      children.push(
        <Col span={4} key={i}>
          <Tooltip title={element.toolTip}>
            <Form.Item
              name={element.name}
              //label={`Field ${i}`}
            >
              {element.node}
            </Form.Item>
          </Tooltip>
        </Col>
      );
    });
    return children;
  };

  const onFinish = (values) => {
    console.log(values);
    setIsLoading(true);
    setTableData(undefined);

    setTimeout(() => {
      axios
        .post(
          "https://potracker.provisionllc.com/api/api/GetSearchResults",
          // "https://localhost:44352/api/GetSearchResults",
          values,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          res.data.forEach((element, index) => {
            element.key = element.ID;
          });
          console.log(res);
          setTimeout(() => {
            console.log("uzzo");
            setIsLoading(false);
          }, 1000);

          setTableData(res.data);
        })
        .catch((err) => {
          setIsLoading(false);
          setTableData([]);
        });
      setIsLoading(false);
    }, 3000);
  };

  return (
    <>
      {
        <div style={{ width: "auto", margin: "5px" }}>
          <Collapse defaultActiveKey={["1"]}>
            <Panel header="Filters:" key="1">
              <Card
                // title="Filters"
                hoverable
                style={{ width: "auto", margin: "2px" }}
              >
                <Form
                  form={form}
                  name="advanced_search"
                  className="ant-advanced-search-form"
                  onFinish={onFinish}
                >
                  <Row gutter={24}>{getFields()}</Row>
                  <Row>
                    <Col span={8} style={{ textAlign: "right" }}>
                      <Button
                        loading={isLoading}
                        type="primary"
                        shape="round"
                        htmlType="submit"
                      >
                        Search
                      </Button>

                      <Button
                        style={{ margin: "0 8px" }}
                        shape="round"
                        onClick={() => {
                          setTableData(null);
                          form.resetFields();
                        }}
                      >
                        Clear
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Panel>
          </Collapse>

          {tableData && <SearchResults dataSource={tableData}></SearchResults>}
        </div>
      }
    </>
  );
};

export default SearchScreenMR;
