import React from "react";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";

import { Layout, Menu } from "antd";
import TopNavBar from "../../Components/TopNavBar";
//import SideNavMenu from "./../../Components/SideNavMenu";
/* import Routes from "../../Routes"; */
import "../../Assets/default.css";

const { Content } = Layout;
export default class Dashboard extends React.Component {
  render() {
    let { title } = this.props;
    return (
      <Layout>
        <TopNavBar {...this.props} />
        <Layout>
          <Layout className="layout-style">
            <Content
              className="main-content-area"
              style={{
                margin: 0,
                padding: 24,
                minHeight: 248,
              }}
            >
              {React.cloneElement(this.props.children, { ...this.props })}
              {/* <Routes {...this.props} /> */}
            </Content>
          </Layout>
        </Layout>
      </Layout>
    );
  }
}
