import React, { Component } from "react";
import "../App.css";
import { Table, DatePicker } from "antd";
//import DatePicker from "react-datepicker";
import moment from "moment";

const col = [
  // {
  //   title: "Est Departure",
  //   dataIndex: "est_dep_date",
  //   key: "est_dep_date",
  //   editable: true,
  //   render: (_, record) => {
  //     return <Moment format="DD/MMM/YYYY">{record.est_dep_date}</Moment>;
  //   },
  //   align: "center",
  // },
  {
    title: "Actual Departure",
    dataIndex: "actual_dep_date",
    key: "actual_dep_date",
    editable: true,
    render: (_, record) => {
      return (
        //<Moment format="DD/MMM/YYYY">{record.est_dep_date}</Moment>;
        <DatePicker
          format="DD/MMM/YYYY"
          defaultValue={
            record.actual_dep_date !== "0001-01-01T00:00:00" &&
            record.actual_dep_date !== null
              ? moment(new Date(record.actual_dep_date), "DD/MMM/YYYY")
              : null
          }
          onChange={(date) => {
            console.log((record.actual_dep_date = date));
          }}
        ></DatePicker>
      );
    },
    align: "center",
  },
  // {
  //   title: "Est. Port Of Entry",
  //   dataIndex: "est_port_entry_date",
  //   key: "est_port_entry_date",
  //   editable: true,
  //   render: (_, record) => {
  //     return <Moment format="DD/MMM/YYYY">{record.est_port_entry_date}</Moment>;
  //   },
  //   align: "center",
  // },
  {
    title: "Actual Port Of Entry",
    dataIndex: "actual_port_entry_date",
    key: "actual_port_entry_date",
    editable: true,
    render: (_, record) => {
      return (
        <DatePicker
          format="DD/MMM/YYYY"
          defaultValue={
            record.actual_port_entry_date !== "0001-01-01T00:00:00" &&
            record.actual_port_entry_date != null
              ? moment(new Date(record.actual_port_entry_date), "DD/MMM/YYYY")
              : null
          }
          onChange={(date) => {
            console.log((record.actual_port_entry_date = date));
          }}
        ></DatePicker>
      );
    },
    align: "center",
  },
  {
    title: "Prod. Completion",
    dataIndex: "prod_completion_date",
    key: "prod_completion_date",
    editable: true,
    render: (_, record) => {
      return (
        <DatePicker
          format="DD/MMM/YYYY"
          defaultValue={
            record.prod_completion_date !== "0001-01-01T00:00:00" &&
            record.prod_completion_date != null
              ? moment(new Date(record.prod_completion_date), "DD/MMM/YYYY")
              : null
          }
          onChange={(date) => {
            record.prod_completion_date =
              date != null ? date.format("DD/MMM/YYYY") : date;
          }}
        ></DatePicker>
      );
    },
    align: "center",
  },

  {
    title: "QC Date",
    dataIndex: "qc_date",
    key: "qc_date",
    editable: true,
    render: (_, record) => {
      return (
        <DatePicker
          format="DD/MMM/YYYY"
          defaultValue={
            record.qc_date !== "0001-01-01T00:00:00" && record.qc_date != null
              ? moment(new Date(record.qc_date), "DD/MMM/YYYY")
              : null
          }
          onChange={(date) => {
            record.qc_date = date != null ? date.format("DD/MMM/YYYY") : date;
          }}
        ></DatePicker>
      );
    },
    align: "center",
  },
  {
    title: "Est. Freight Rate($)",
    dataIndex: "est_freight_rate",
    key: "est_freight_rate",
    align: "center",
  },
];

export default class Shipment extends Component {
  render() {
    const propData = this.props.content;
    return (
      <Table
        columns={col}
        dataSource={propData}
        pagination={false}
        bordered
        size="small"
      ></Table>
    );
  }
}
