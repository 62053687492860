import React, { useState, useEffect } from "react";
import { poTableCol } from "../ViewPoTableCol";
import "../App.css";
import { Modal, Card, Button, Table, Divider, Spin, message } from "antd";
import data from "../pomock.json";
import CreateShipment from "./CreateShipment";
import API from "../API/APIUtil";

const col = poTableCol;

const api = new API();
let tableData = [];
export default function ViewPurchaseOrders() {
  const [checkedRows, setCheckedRows] = useState([]);

  const [popUpVisible, setPopUpVisible] = useState(false);
  const [fieldsMismatchAlert, setFieldsMismatchAlert] = useState(false);
  const [viewSpin, setViewSPin] = useState(true);

  useEffect(() => {
    api.createEntity({ name: "GetPoInfo" });
    api.endpoints.GetPoInfo.getAll().then((res) => {
      tableData = res.data;
      tableData.forEach((element, index) => {
        element.key = index;
      });

      setViewSPin(false);
    });
  });

  const expandableObj = {
    expandedRowRender: (record, index, indent, expanded) => {
      if (!expanded) return;
      //console.log(record, expanded);
      const columns = [
        {
          title: " Carton Size(Cm)",
          children: [
            {
              title: "Length",
              dataIndex: "length_cm",
              key: "length_cm",
              align: "right",
            },
            {
              title: "width",
              dataIndex: "width_cm",
              key: "width_cm",
              align: "right",
            },
            {
              title: "Height",
              dataIndex: "height_cm",
              key: "height_cm",
              align: "right",
            },
          ],
        },
        {
          title: " Carton Size(In)",
          children: [
            {
              title: "Length",
              dataIndex: "length_in",
              key: "length_in",
              align: "right",
            },
            {
              title: "Width",
              dataIndex: "width_in",
              key: "width_in",
              align: "right",
            },
            {
              title: "Height",
              dataIndex: "height_in",
              key: "height_in",
              align: "right",
            },
          ],
        },
        { title: "G.W.(Kg)", dataIndex: "gw_kg", key: "gw_kg", align: "right" },
        {
          title: "G.W.(Lbs)",
          dataIndex: "gw_lbs",
          key: "gw_lbs",
          align: "right",
        },

        {
          title: "Total G.W.(Kg) on Qty",
          dataIndex: "totalGwOnQtyKg",
          key: "totalGwOnQtyKg",
          align: "right",
        },
        {
          title: "Total G.W.(Lbs) on Qty",
          dataIndex: "totalGwOnQtyLbs",
          key: "totalGwOnQtyLbs",
          align: "right",
        },
        {
          title: "Vol. Per Carton(Cm^3)",
          dataIndex: "volforEachCartonOnQty",
          key: "volforEachCartonOnQty",
          align: "right",
        },
        {
          title: "Total Vol.(Cm^3)",
          dataIndex: "totalVolWeightOnQtyCm",
          key: "totalVolWeightOnQtyCm",
          align: "right",
        },
        {
          title: "Case Price($)",
          dataIndex: "unit_case_price",
          key: "unit_case_price",
          width: 100,
          align: "right",
        },
        {
          title: "Case Pack",
          dataIndex: "case_pack",
          key: "case_pack",
          width: 100,
          align: "right",
        },
      ];

      const data = [];

      data.push({
        key: record.key,
        case_pack: record.case_pack,
        quantity: record.quantity,
        unit_price: record.unit_price,
        unit_case_price: record.unit_case_price,
        length_cm: record.length_cm,
        width_cm: record.width_cm,
        height_cm: record.height_cm,
        length_in: record.length_in,
        width_in: record.width_in,
        height_in: record.height_in,
        gw_kg: record.gw_kg,
        gw_lbs: record.gw_lbs,
        totalGwOnQtyKg: record.totalGwOnQtyKg,
        totalGwOnQtyLbs: record.totalGwOnQtyLbs,
        volforEachCartonOnQty: record.volforEachCartonOnQty,
        totalVolWeightOnQtyCm: record.totalVolWeightOnQtyCm,
      });

      return (
        <div>
          {viewSpin && <Spin size="large" />}
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            scroll={{ y: 500 }}
            size="small"
            bordered
          />
          <Divider></Divider>
        </div>
      );
    },
  };

  const showModal = () => {
    if (!checkedRows.length) {
      message.error("Select atleast one line item for shipment");
      return;
    }

    let deliverTo = checkedRows[0].deliver_to;
    let shippingAddress = String(
      checkedRows[0].shipping_addressL1 +
        checkedRows[0].shipping_address_city +
        checkedRows[0].shipping_address_state +
        checkedRows[0].shipping_address_postal
    )
      .toLocaleLowerCase()
      .trim();
    console.log(checkedRows);
    if (
      !checkedRows.every((row) => {
        return (
          String(row.deliver_to).toLocaleLowerCase().trim() ===
          String(deliverTo).toLocaleLowerCase().trim()
        );
      })
    ) {
      message.error(
        "Deliver To field does not match for all selected line items"
      );
      // alert("Deliver To field does not match for all selected line items");
      setFieldsMismatchAlert(true);
      return;
    }
    if (
      !checkedRows.every((row) => {
        return (
          String(
            row.shipping_addressL1 +
              row.shipping_address_city +
              row.shipping_address_state +
              row.shipping_address_postal
          )
            .toLocaleLowerCase()
            .trim() === shippingAddress
        );
      })
    ) {
      message.error("Address Fields do not match for selected line items");
      // alert("Address Fields do not match for selected line items");
      setFieldsMismatchAlert(true);
      return;
    }

    setPopUpVisible(true);
  };

  const handleOk = () => {
    setPopUpVisible(false);
  };

  const handleCancel = () => {
    setPopUpVisible(false);
  };

  const handleRowSelectionChange = (selectedRowKeys, selectedRows) => {
    setCheckedRows(selectedRows);
    console.log(selectedRows);
  };

  const rowSelection = {
    onChange: handleRowSelectionChange,
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
    columnWidth: 30,
  };

  return (
    <>
      {/* {fieldsMismatchAlert && (
        <Alert
          message="Error"
          description="This is an error message about copywriting."
          type="error"
          showIcon
          closable
        />
      )} */}
      <Card title="Purchase Orders" style={{ width: "100%" }}>
        <Table
          rowSelection={rowSelection}
          expandable={expandableObj}
          columns={col}
          dataSource={tableData}
          //dataSource={data}
          scroll={{ x: "calc(400px + 50%)" }}
          bordered
          size="small"
          sticky
        ></Table>
        <Button type="primary" shape="round" onClick={showModal}>
          {" "}
          Go To Create Shipment
        </Button>

        <Modal
          title="Create Shipment"
          visible={popUpVisible}
          destroyOnClose
          onOk={handleOk}
          onCancel={handleCancel}
          width={1200}
          // footer={[
          //   <Button key="back" onClick={handleCancel}>
          //     Return
          //   </Button>,
          // ]}
          centered
        >
          <CreateShipment selectedItems={checkedRows}></CreateShipment>
        </Modal>
        {/*} <div style={divStyle}>
     
      </div>*/}
      </Card>
    </>
  );
}
