import React from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import { Route, Switch, Redirect } from "react-router-dom";
import RouteWithLayout from "./Routes/RoutesHandler";
import Dashboard from "./Views/Layouts/Dashboard";
import TestHome from "./Views/TestHome";
import POApp from "./POApp";
import AuthService from "./Service/AuthService";
import LoginPage from "./Views/LoginPage";
import POUpload from "./Views/POUpload";
import ViewPurchaseOrders from "./Views/ViewPurchaseOrders";
import Shipments from "./Views/ViewShipments";
import { PermissionsList } from "./Service/PermissionList";
import SearchScreenMR from "./Views/SearchScreenMR";

export default function routes({ ...rest }) {
  return (
    <div>
      <Switch>
        <Route
          exact
          path="/login"
          render={(props) => {
            return AuthService.authenticated ? (
              <Redirect to="/" />
            ) : (
              <LoginPage {...props} title={"login"} />
            );
          }}
        />
        <RouteWithLayout
          exact
          path="/"
          layout={Dashboard}
          component={TestHome}
        />
        <RouteWithLayout
          exact
          path="/POApp/shipments"
          layout={Dashboard}
          permission={PermissionsList.POTrackerPermission}
          component={POApp}
        />
        <RouteWithLayout
          exact
          path="/POApp/createShipment"
          layout={Dashboard}
          permission={PermissionsList.POTrackerPermission}
          component={POApp}
        />
        <RouteWithLayout
          exact
          path="/POApp/upload"
          layout={Dashboard}
          permission={PermissionsList.POTrackerPermission}
          component={POApp}
        />
        <RouteWithLayout
          exact
          path="/POApp"
          permission={PermissionsList.POTrackerPermission}
          layout={Dashboard}
          component={POApp}
        />
        <RouteWithLayout
          exact
          path="/MRApp"
          layout={Dashboard}
          permission={PermissionsList.MarketingResearchPermission}
          component={SearchScreenMR}
        />
        {/*<RouteWithLayout
          exact
          path="/createShipment"
          layout={Dashboard}
          component={ViewPurchaseOrders}
        />
        <RouteWithLayout
          exact
          path="/upload"
          layout={Dashboard}
          component={POUpload}
      />*/}
      </Switch>
    </div>
  );
}
