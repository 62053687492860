import React from "react";
import logo from "./logo.png";
import "./App.css";
import POUpload from "./Views/POUpload";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Shipments from "./Views/ViewShipments";
//import CreateShipment from "./Views/CreateShipment";
import ViewPurchaseOrders from "./Views/ViewPurchaseOrders";
import SearchScreenMR from "./Views/SearchScreenMR";
import POApp from "./POApp";
import Routes from "./routes";

import { Menu, Button } from "antd";
import {
  MailOutlined,
  AppstoreOutlined,
  SettingOutlined,
} from "@ant-design/icons";

const App = () => {
  return (
    <Router>
      {/*<Route exact path="/" component={POApp}></Route>
        <Route path="/POApp" component={POApp}></Route>
  <Route path="/MRApp" component={SearchScreenMR}></Route>*/}
      <Routes></Routes>
    </Router>
  );
};

export default App;
