import React, { Component } from "react";
import "../App.css";
import { Redirect } from "react-router-dom";
import {
  DatePicker,
  Form,
  Input,
  InputNumber,
  Button,
  Table,
  Row,
  Col,
  Divider,
  Alert,
  Select,
  Tooltip,
} from "antd";
import Moment from "react-moment";
import API from "../API/APIUtil";

const api = new API();
const { Option } = Select;

const col = [
  {
    title: "PO",
    align: "center",
    dataIndex: "po_number",
    key: "po_number",
    fixed: "left",
  },
  {
    title: "PO Date",
    align: "center",
    dataIndex: "po_date",
    key: "po_date",
    render: (_, record) => {
      return <Moment format="DD/MMM/YYYY">{record.po_date}</Moment>;
    },
  },

  {
    title: "Deliver To",
    align: "center",
    dataIndex: "deliver_to",
    key: "deliver_to",
  },
  {
    title: "Purchase order details",
    children: [
      {
        title: "Item",
        dataIndex: "name",
        key: "name",
        editable: true,
      },
      {
        title: "Case Pack",
        dataIndex: "case_pack",
        key: "case_pack",
      },
      {
        title: "Cartons",
        dataIndex: "quantity",
        key: "quantity",
        align: "right",
      },
      {
        title: "Unit Price($)",
        dataIndex: "unit_price",
        key: "unit_price",
        align: "right",
      },
      {
        title: "Case price",
        dataIndex: "unit_case_price",
        key: "unit_case_price",
        align: "right",
      },
    ],
  },
  {
    title: " Carton size(Cm)",
    children: [
      {
        title: "Length",
        dataIndex: "length_cm",
        key: "length_cm",
        align: "right",
      },
      {
        title: "width",
        dataIndex: "width_cm",
        key: "width_cm",
        align: "right",
      },
      {
        title: "height",
        dataIndex: "height_cm",
        key: "height_cm",
        align: "right",
      },
    ],
  },

  {
    title: "Total($)",
    dataIndex: "total_buy",
    key: "total_buy",
    align: "right",
  },
];

export default class ShipmentComponent extends Component {
  state = { created: false, error: false, redirect: null };
  onChangeArrivalDate = (date, dateString) => {};

  onChangeShipmentId = (val) => {};

  onFormChange = (changedValues, allValues) => {};
  onFinish = (values) => {
    values.poDetailIds = this.props.selectedItems.map((record) => {
      return record.po_details_id;
    });
    console.log(values);
    api.createEntity({ name: "CreateShipment" });

    api.endpoints.CreateShipment.create(values, {
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          this.setState({ created: true, error: false });
          setTimeout(() => {
            this.setState({ redirect: "/shipments" });
          }, 1500);
        }
      })
      .catch((error) => {
        this.setState({ error: true, created: false });
      });
  };

  render() {
    return (
      <div>
        {this.state.created && (
          <Alert
            type="success"
            message="Shipment created successfully!"
            showIcon
            closable
          ></Alert>
        )}
        {this.state.redirect && <Redirect to={this.state.redirect} />}
        {this.state.error && (
          <Alert type="Error" message="Server error"></Alert>
        )}
        <Form
          layout="horizontal"
          name="control-hooks"
          onFinish={this.onFinish}
          onValuesChange={this.onFormChange}
          size="small"
        >
          <Row gutter={16}>
            <Form.Item
              style={{ margin: "auto" }}
              name="shipmentNo"
              initialValue={
                this.props.selectedItems[0].deliver_to +
                "_" +
                this.props.selectedItems[0].po_number
              }
              label="Shipment Name:"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input style={{ width: 300 }}></Input>
            </Form.Item>
          </Row>
          <Row gutter={16}>
            <Divider></Divider>
            <Col span={6}>
              <Tooltip title="QC Date">
                <Form.Item
                  name="qc_date"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <DatePicker
                    style={{
                      width: "200px",
                    }}
                    placeholder="QC. Date"
                  ></DatePicker>
                </Form.Item>
              </Tooltip>
            </Col>
            <Col span={6}>
              <Tooltip title="Prod. Completion Date">
                <Form.Item
                  name="prod_completion_date"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <DatePicker
                    style={{
                      width: "200px",
                    }}
                    placeholder="Prod. Completion Date"
                  ></DatePicker>
                </Form.Item>
              </Tooltip>
            </Col>
            <Col className="gutter-row" span={6}>
              <Form.Item name="shipping_mode" label="Shipping Mode:">
                <Select
                  defaultValue={String(
                    this.props.selectedItems[0].shipping_mode
                  ).toLocaleLowerCase()}
                  style={{ width: 120 }}
                  onChange={(val) => {
                    this.props.selectedItems[0].shipping_mode = val;
                  }}
                >
                  <Option value="air">Air</Option>
                  <Option value="sea">Sea</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={6}>
              <Form.Item name="estFreightRate" label="Est. Freight rate:">
                <InputNumber
                  style={{ width: 100 }}
                  value={this.props.selectedItems[0].est_freight_rate}
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                />
              </Form.Item>
            </Col>
          </Row>
          <br></br>
          <Table
            columns={col}
            dataSource={this.props.selectedItems}
            scroll={{ x: "calc(700px + 50%)", y: 240 }}
            bordered
            pagination={false}
          ></Table>
          <Form.Item>
            <Divider></Divider>
            <Button type="primary" shape="round" htmlType="submit">
              Create Shipment
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}
