import React, { Component } from "react";
import Shipment from "../Components/ShipmentCard";
import QcDetails from "../Views/QcDetails";
import ViewShipmentDetails from "../Components/ViewShipmentDetails";
import { CargoShipIcon, CargoPlaneIcon } from "../Components/Icons";

import API from "../API/APIUtil";
import "../App.css";
import { Collapse, Card, Button, Divider } from "antd";

const api = new API();
let tableData = [];

const { Panel } = Collapse;
const tabList = [
  {
    key: "shipmentTab",
    tab: "Shipment info",
  },

  {
    key: "qcDetailsTab",
    tab: "QC Details",
  },
];

export default class Shipments extends Component {
  state = {
    key: "shipmentTab",
    index: 0,
    selectedRowKeys: [],
    checkedRows: [],
    visible: false,
    confirmLoading: false,
    showComp: false,
    refresh: false,
  };

  componentDidMount() {
    api.createEntity({ name: "GetShipmentData" });

    api.endpoints.GetShipmentData.getAll().then((res) => {
      console.log(res.data);
      tableData = res.data;
      this.setState({ showComp: true });
    });
  }

  onTabChange = (key, type, newIndex) => {
    this.setState({ [type]: key, index: newIndex });
  };
  onDetailsClick = () => {
    console.log("Details clicked");
  };
  onFinish = (values) => {
    console.log("Received values from form: ", values);
  };
  render() {
    return (
      <div
        style={{
          margin: "auto",
          width: "95%",

          padding: "5px",
        }}
      >
        {/*} <Card title="Filter Shipments" style={{ width: "95%" }}>
          <Form
            name="customized_form_controls"
            layout="inline"
            onFinish={this.onFinish}
            initialValues={{
              price: {
                number: 0,
                currency: "rmb",
              },
            }}
          >
            <Form.Item name="price" label="Price">
              <Input></Input>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Card>
          <Divider></Divider>*/}
        {this.state.showComp &&
          tableData.map((shipment, currIndex) => {
            shipment.shipmentinfo.key = currIndex;
            shipment.shipmentdetails.forEach((element, index) => {
              element.key = index;
            });
            shipment.shipmentinfo.forEach((element, index) => {
              element.key = index;
            });

            return (
              <div
                key={currIndex}
                style={{
                  margin: "auto",
                  width: "99%",

                  padding: "0px",
                }}
              >
                <Card
                  title={`${shipment.shipmentinfo[0].shipment_no} `}
                  hoverable
                  extra={
                    shipment.shipmentinfo[0].shipping_mode !== null ? (
                      shipment.shipmentinfo[0].shipping_mode === "air" ? (
                        <CargoPlaneIcon></CargoPlaneIcon>
                      ) : (
                        <CargoShipIcon></CargoShipIcon>
                      )
                    ) : null
                  }
                  style={{ width: "100%" }}
                  /* title={`Shipment: ` + shipment.shipmentinfo[0].shipment_no}*/

                  tabList={tabList}
                  activeTabKey={this.state.key}
                  onTabChange={(key) => {
                    this.onTabChange(key, "key", currIndex);
                  }}
                >
                  {this.state.key === "shipmentTab" ? (
                    <>
                      <Shipment content={shipment.shipmentinfo}></Shipment>

                      <Collapse>
                        <Panel header="Shipment Line Items" key="1">
                          {
                            <ViewShipmentDetails
                              estFreightRate={
                                shipment.shipmentinfo[0].est_freight_rate
                              }
                              content={shipment.shipmentdetails}
                              id={shipment.shipmentdetails.shipment_id}
                            ></ViewShipmentDetails>
                          }
                        </Panel>
                      </Collapse>
                    </>
                  ) : (
                    <QcDetails content={shipment.shipmentinfo}></QcDetails>
                  )}
                  <br />
                  <Button
                    type="primary"
                    onClick={() => {
                      let payload = [];
                      payload = shipment.shipmentinfo[0];
                      payload.shipmentDetails = shipment.shipmentdetails;
                      console.log(payload);
                      api.createEntity({ name: "UpdateShipment" });

                      api.endpoints.UpdateShipment.create(payload, {
                        headers: { "Content-Type": "application/json" },
                      })
                        .then((res) => {
                          this.setState({ refresh: true });
                        })
                        .catch((error) => {
                          console.log(error, ": fail");
                        });
                    }}
                  >
                    Save
                  </Button>
                </Card>
                <Divider></Divider>
              </div>
            );
          })}
        {}
      </div>
    );
  }
}
