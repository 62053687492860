import React, { useEffect, useState } from "react";
import { Table, Card, Popover, Typography, Form } from "antd";
import OfficeDetails from "./OfficeDetails";
const { Title, Text } = Typography;

export default function MasterDataDetails(props) {
  useEffect(() => {
    form.resetFields();
    form2.resetFields();
  });

  const selectedRowTable = props.selectedRowTable;

  const getProjectDetails = (row) => {
    let cols = [];
    if (row.hardBrand !== "" || row.softBrands !== "") {
      cols = [
        {
          title: "Hard Brand/Soft Brand",
          dataIndex: "hardBrand",
          render: (_, record) => {
            return (
              <Text>
                {row.hardBrand +
                  (row.softBrands === "" ? "" : "/" + row.softBrands)}
              </Text>
            );
          },
          align: "center",
        },
        ...columns,
      ];
    } else cols = [...columns];
    const data = [];
    data.push(row);
    return (
      <Table
        bordered
        dataSource={data}
        columns={cols}
        pagination={false}
        size="small"
      ></Table>
    );
  };

  const columns = [
    { title: "Phase", dataIndex: "phase", align: "center" },
    { title: "Category", dataIndex: "category", align: "center" },
    { title: "Stars", dataIndex: "stars", align: "center" },
    { title: "Rooms", dataIndex: "rooms", align: "center" },
    { title: "Opening Date", dataIndex: "openingDate", align: "center" },
    { title: "Investment (In Mil. $)", dataIndex: "tbi", align: "center" },

    { title: "Continent", dataIndex: "continent", align: "center" },
    { title: "Country", dataIndex: "country", align: "center" },
    { title: "State", dataIndex: "state", align: "center" },
    { title: "City", dataIndex: "city", align: "center" },
  ];
  const tabList = [
    {
      key: "architect",
      tab: "Architect",
    },
    {
      key: "interior designer",
      tab: "Interior Designer",
    },
    {
      key: "management company",
      tab: "Management Company",
    },
    {
      key: "general contractor",
      tab: "General Contractor",
    },
    {
      key: "investor/ owner",
      tab: "Investor/Owner",
    },
    {
      key: "developer",
      tab: "Developer",
    },
    {
      key: "consultant",
      tab: "Consultant",
    },
    {
      key: "purchasing company",
      tab: "Purchasing Company",
    },
    {
      key: "other",
      tab: "Other",
    },
  ];
  const tabContentList = {
    //only pass the role and projectId as props,get data based on that role from DB in next component.
    architect: (
      <OfficeDetails
        getSelectedRowsPropMethod={props.selectedRowsProp}
        getSelectedRowKeysPropMethod={props.selectedRowkeysProp}
        selection={props.setSelectedRows}
        projectId={selectedRowTable.projectId}
        role="Architect"
      ></OfficeDetails>
    ),
    "interior designer": (
      <OfficeDetails
        getSelectedRowsPropMethod={props.selectedRowsProp}
        getSelectedRowKeysPropMethod={props.selectedRowkeysProp}
        selection={props.setSelectedRows}
        projectId={selectedRowTable.projectId}
        role="Interior Designer"
      ></OfficeDetails>
    ),
    "management company": (
      <OfficeDetails
        getSelectedRowsPropMethod={props.selectedRowsProp}
        getSelectedRowKeysPropMethod={props.selectedRowkeysProp}
        selection={props.setSelectedRows}
        projectId={selectedRowTable.projectId}
        role="Management Company"
      ></OfficeDetails>
    ),
    "general contractor": (
      <OfficeDetails
        getSelectedRowsPropMethod={props.selectedRowsProp}
        getSelectedRowKeysPropMethod={props.selectedRowkeysProp}
        selection={props.setSelectedRows}
        projectId={selectedRowTable.projectId}
        role="General Contractor"
      ></OfficeDetails>
    ),
    "investor/ owner": (
      <OfficeDetails
        getSelectedRowsPropMethod={props.selectedRowsProp}
        getSelectedRowKeysPropMethod={props.selectedRowkeysProp}
        selection={props.setSelectedRows}
        projectId={selectedRowTable.projectId}
        role="Investor/ Owner"
      ></OfficeDetails>
    ),
    developer: (
      <OfficeDetails
        getSelectedRowsPropMethod={props.selectedRowsProp}
        getSelectedRowKeysPropMethod={props.selectedRowkeysProp}
        selection={props.setSelectedRows}
        projectId={selectedRowTable.projectId}
        role="Developer"
      ></OfficeDetails>
    ),
    consultant: (
      <OfficeDetails
        getSelectedRowsPropMethod={props.selectedRowsProp}
        getSelectedRowKeysPropMethod={props.selectedRowkeysProp}
        selection={props.setSelectedRows}
        projectId={selectedRowTable.projectId}
        role="Consultant"
      ></OfficeDetails>
    ),
    "purchasing company": (
      <OfficeDetails
        getSelectedRowsPropMethod={props.selectedRowsProp}
        getSelectedRowKeysPropMethod={props.selectedRowkeysProp}
        selection={props.setSelectedRows}
        projectId={selectedRowTable.projectId}
        role="Purchasing Company"
      ></OfficeDetails>
    ),
    other: (
      <OfficeDetails
        getSelectedRowsPropMethod={props.selectedRowsProp}
        getSelectedRowKeysPropMethod={props.selectedRowkeysProp}
        selection={props.setSelectedRows}
        projectId={selectedRowTable.projectId}
        role="other"
      ></OfficeDetails>
    ),
  };

  const getDefaultActiveTabKey = (officeRole) => {
    if (tabList.filter((value) => value.key === officeRole).length > 0)
      return officeRole;
    else return "other";
  };
  const [form] = Form.useForm();
  const [form2] = Form.useForm();

  const [activeTab, setActiveTab] = useState(
    getDefaultActiveTabKey(selectedRowTable.officeRole.toString().toLowerCase())
  );

  return (
    <div>
      <Card bodyStyle={{ padding: "5px" }}>
        <Title level={4}>
          {selectedRowTable !== null
            ? selectedRowTable.projectName +
              " (#" +
              selectedRowTable.projectId +
              ")"
            : ""}
        </Title>
        <Popover
          title="Description"
          trigger="click"
          content={selectedRowTable.description}
        >
          <Title level={5}>Description</Title>
        </Popover>

        {getProjectDetails(selectedRowTable)}
      </Card>
      <br></br>
      <Card
        tabList={tabList}
        activeTabKey={activeTab}
        hoverable
        onTabChange={(key) => {
          setActiveTab(key);
        }}
        bodyStyle={{ padding: "5px" }}
      >
        {tabContentList[activeTab]}
      </Card>
    </div>
  );
}
