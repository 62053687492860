import CookieService from "../Service/CookieService";
import CookieList from "./CookieList";
import { withRouter, useHistory, Redirect } from "react-router-dom";
//import PermissionsList from "./Service/PermissionList";

class AuthService {
  constructor() {
    this.authenticated = CookieService.get(CookieList.user_token)
      ? true
      : false;
  }
  login() {
    this.authenticated = true;
  }
  logout() {
    //TODO: api call to logout
    // return CookieService.removeList(CookieList.toClear);
    CookieService.removeAllCookies(); //CookieService.remove(CookieList.user_token);
    //history.push("/login");
    // if (cookieDeleted) this.props.history.push("/login");
  }
  isAuthenticated() {
    return this.authenticated; //CookieService.get(CookieList.user_token) ? true : false;
  }
  //PermissionType: pass the permission type from PermissionList
  // isUserPermitted(PermissionType) {
  //   console.log(" permission type",PermissionType);
  //   let response = {
  //     permission_list: null,
  //     access: false,
  //   };
  //   if (PermissionType === PermissionList.no_permission) {
  //     response = {
  //       permission_list: null,
  //       access: true,
  //     };
  //     return response;
  //   } else {
  //     const permissionsList = CookieService.get(CookieList.user_permissions);
  //     permissionsList.map((permission) => {
  //       console.log("per mission", permission);
  //       if (permission.feature == PermissionType) {
  //         response = {
  //           permission_list: permission.permissions,
  //           access: true,
  //         };
  //         console.log("response::", response);
  //         return response;
  //       }
  //     });
  //   }
  //   console.log("response after", response);
  //   return response;
  // }

  //PermissionType | SubPermissionType : pass the permission type from PermissionList
  //subType: true:if the permission is under a feature
  isPermitted(PermissionType, SubPermissionType = null, subType = false) {
    console.log(PermissionType);
    //check if permissionType is same as permissions in cookie for this user

    let permissionList = CookieService.get(CookieList.user_permissions);
    console.log(permissionList);
    if (permissionList.includes(PermissionType)) return true;
    else return false;
  }
}
export default new AuthService();
