import React, { useState } from "react";
import { Row, Col, Card, Button } from "antd";
import { Redirect } from "react-router-dom";
import AuthService from "../Service/AuthService";
import { PermissionsList } from "../Service/PermissionList";
const { Meta } = Card;

export default function TestHome() {
  const [redirect, setredirect] = useState(null);

  return (
    <div
      style={{ margin: "auto", width: "90%", padding: "10px", zIndex: "50" }}
    >
      {redirect && <Redirect to={redirect} />}
      <Row>
        <Col span={8}>
          <Card
            cover={
              <img
                alt="example"
                src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
              />
            }
            style={{
              width: 300,
              background: " #fbfbfb",
              "box-shadow": "0px 4px 4px rgba(0, 0, 0, 0.25)",
              "border-radius": "20px",
            }}
            actions={[
              <Button
                disabled={
                  !AuthService.isPermitted(PermissionsList.POTrackerPermission)
                }
                type="primary"
                onClick={() => {
                  setredirect("/POApp");
                }}
              >
                Open Application
              </Button>,
            ]}
            hoverable
          >
            <Meta
              title="Track Purchase Orders"
              description="App description goes here"
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card
            cover={
              <img
                alt="example"
                src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
              />
            }
            style={{
              width: 300,
              background: " #fbfbfb",
              "box-shadow": "0px 4px 4px rgba(0, 0, 0, 0.25)",
              "border-radius": "20px",
            }}
            actions={[
              <Button
                disabled={
                  !AuthService.isPermitted(
                    PermissionsList.MarketingResearchPermission
                  )
                }
                type="primary"
                onClick={() => {
                  setredirect("/MRApp");
                }}
              >
                Open Application
              </Button>,
            ]}
            hoverable
          >
            <Meta
              title="Hospitality Marketing Analysis"
              description="App description goes here"
            />
          </Card>
        </Col>

        <Col span={8}>
          <Card
            cover={
              <img
                alt="example"
                src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
              />
            }
            style={{
              width: 300,
              background: " #fbfbfb",
            }}
            actions={[
              <a href="https://www.cognitoforms.com/ProvisionAdvisoryServicesLLC/supplierlogin">
                Go to supplier login
              </a>,
            ]}
            hoverable
          >
            <Meta
              title="Supplier Login"
              description="App description goes here"
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
}
