import React, { useState } from "react";
import API from "../API/APIUtil";
import { Redirect } from "react-router-dom";
import { poTableCol } from "../TableCol";
import { Table, Button, Divider, Card, Alert } from "antd";

const api = new API();
const tableStyle = {
  margin: "auto",
  width: "1300px",
  padding: "5px",
};
const POUpload = (props) => {
  const [selectedFile, setSelectedFile] = useState();
  const [viewTable, setViewTable] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [created, setIsCreated] = useState(false);
  const [error, setIsError] = useState(false);
  const [redirect, setRedirect] = useState(null);

  const onFileChange = (event) => {
    // Update the state
    setSelectedFile(event.target.files[0]);
  };
  const expandableObj = {
    expandedRowRender: (record, index, indent, expanded) => {
      if (!expanded) return;
      //console.log(record, expanded);
      const columns = [
        {
          title: " Carton Size(Cm)",
          children: [
            {
              title: "Length",
              dataIndex: "lengthCm",
              key: "lengthCm",
              align: "right",
            },
            {
              title: "width",
              dataIndex: "widthCm",
              key: "widthCm",
              align: "right",
            },
            {
              title: "Height",
              dataIndex: "heightCm",
              key: "heightCm",
              align: "right",
            },
          ],
        },
        {
          title: " Carton Size(In)",
          children: [
            {
              title: "Length",
              dataIndex: "lengthIn",
              key: "lengthIn",
              align: "right",
            },
            {
              title: "Width",
              dataIndex: "widthIn",
              key: "widthIn",
              align: "right",
            },
            {
              title: "Height",
              dataIndex: "heightIn",
              key: "heightIn",
              align: "right",
            },
          ],
        },
        { title: "G.W.(Kg)", dataIndex: "gwKg", key: "gwKg", align: "right" },
        {
          title: "G.W.(Lbs)",
          dataIndex: "gwLbs",
          key: "gwlbs",
          align: "right",
        },

        {
          title: "Total G.W.(Kg) on Qty",
          dataIndex: "totalGwOnQtyKg",
          key: "totalGwOnQtyKg",
          align: "right",
        },
        {
          title: "Total G.W.(Lbs) on Qty",
          dataIndex: "totalGwOnQtyLbs",
          key: "totalGwOnQtyLbs",
          align: "right",
        },
        {
          title: "Vol. Per Carton(Cm^3)",
          dataIndex: "volforEachCartonOnQty",
          key: "volforEachCartonOnQty",
          align: "right",
        },
        {
          title: "Total Vol.(Cm^3)",
          dataIndex: "totalVolWeightOnQtyCm",
          key: "totalVolWeightOnQtyCm",
          align: "right",
        },
      ];

      const data = [];

      data.push({
        key: record.key,

        quantity: record.quantity,
        unit_price: record.unit_price,
        unit_case_price: record.unit_case_price,
        lengthCm: record.lengthCm,
        widthCm: record.widthCm,
        heightCm: record.heightCm,
        lengthIn: record.lengthIn,
        widthIn: record.widthIn,
        heightIn: record.heightIn,
        gwKg: record.gwKg,
        gwLbs: record.gwLbs,
        totalGwOnQtyKg: record.totalGwOnQtyKg,
        totalGwOnQtyLbs: record.totalGwOnQtyLbs,
        volforEachCartonOnQty: record.volforEachCartonOnQty,
        totalVolWeightOnQtyCm: record.totalVolWeightOnQtyCm,
      });

      return (
        <div>
          <Table
            columns={columns}
            dataSource={data}
            scroll={{ x: "calc(1500px + 50%)", y: 500 }}
            pagination={false}
            scroll={{ y: 500 }}
            size="small"
            bordered
          />
          <Divider></Divider>
        </div>
      );
    },
  };

  const data = [];
  let key = 1;

  const onFileUpload = () => {
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append("myFile", selectedFile, selectedFile.name);

    // Details of the uploaded file
    console.log(selectedFile);

    // Request made to the backend api
    // Send formData object

    api.createEntity({ name: "purchaseOrderUpload" });
    api.endpoints.purchaseOrderUpload.create(formData).then((res) => {
      //<FetchPo responseData={res} />;
      console.log(res.data);
      res.data.forEach((element) => {
        element.key = key++;
        data.push(element);
      });
      setTableData(data);
      console.log(JSON.stringify(data));
      setViewTable(true);
    });
  };
  const handleSave = () => {
    api.createEntity({ name: "purchaseOrderUploadSave" });

    api.endpoints.purchaseOrderUploadSave
      .create(tableData, { headers: { "Content-Type": "application/json" } })
      .then((res) => {
        if (res.status === 201) {
          console.log(res, ": Success");
          setIsCreated(true);
          setTimeout(() => {
            setRedirect("/createShipment");
          }, 2000);
        }
      })
      .catch((error) => {
        console.log(error, ": fail");
        setIsError(true);
      });
  };

  const fileData = () => {
    if (selectedFile && !viewTable) {
      return (
        <div>
          <h2>File Details:</h2>
          <p>File Name: {selectedFile.name}</p>
          <p>File Type: {selectedFile.type}</p>
          <p>Last Modified: {selectedFile.lastModifiedDate.toDateString()}</p>
        </div>
      );
    } else {
      return (
        <div>
          <br />
        </div>
      );
    }
  };
  const divStyle = {
    position: "absolute",

    height: "1000px",
    width: "auto",
    margin: "20px",
  };

  return (
    <Card title="Upload Purchase Orders" style={{ width: "100%" }}>
      <div>
        <div>
          <input type="file" onChange={onFileChange} />
          <Button type="primary" onClick={onFileUpload}>
            Upload
          </Button>

          {redirect && <Redirect to={redirect} />}
          {error && (
            <Alert
              type="error"
              message="Server error.Something went wrong"
              showIcon
              closable
            ></Alert>
          )}
          {viewTable && (
            <div>
              <Table
                expandable={expandableObj}
                columns={poTableCol}
                dataSource={tableData}
                bordered
                scroll={{ y: 500 }}
                style={tableStyle}
                sticky
                size="small"
              ></Table>
              <Button type="primary" onClick={handleSave}>
                SAVE POs
              </Button>
            </div>
          )}
          {created && (
            <Alert
              type="success"
              message="Purchase Orders uploaded successfully!"
              showIcon
              closable
            ></Alert>
          )}
        </div>
        {fileData()}
      </div>
    </Card>
  );
};

export default POUpload;
