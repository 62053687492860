import axios from "axios";

//const BaseUrl = "https://localhost:44352/api";
const BaseUrl = "https://potracker.provisionllc.com/api/api";
//const BaseUrl = "http://172.16.2.52:8084/api";

export default class API {
  constructor() {
    this.url = BaseUrl;
    this.endpoints = {};
  }
  /**
   *@param {An entity Object} entity
   */

  createEntity(entity) {
    this.endpoints[entity.name] = this.createBasicCRUDEndpoints(entity);
  }

  createEntities(arrayOfEntity) {
    arrayOfEntity.forEach(this.createEntity.bind(this));
  }

  createBasicCRUDEndpoints({ name }) {
    let endpoints = {};
    const resourceURL = `${this.url}/${name}`;

    endpoints.getAll = ({ query } = {}) => {
      return axios.get(resourceURL, { params: { query } });
    };
    endpoints.getOne = ({ id }) => {
      return axios.get(`${resourceURL}/${id}`);
    };
    endpoints.create = (toCreate, config) =>
      axios.post(resourceURL, toCreate, config);
    endpoints.update = (toUpdate) =>
      axios.put(`${resourceURL}/${toUpdate.id}`, toUpdate);
    endpoints.delete = ({ id }) => axios.delete(`${resourceURL}/${id}`);
    return endpoints;
  }
}
