import Moment from "react-moment";
import React from "react";

export const poTableCol = [
  {
    title: "PO",
    dataIndex: "po_number",
    key: "po_number",
    width: 100,
    fixed: "left",
    align: "center",
  },
  {
    title: "PO Date",
    dataIndex: "po_date",
    key: "po_date",
    width: 100,
    align: "center",

    render: (_, record) => {
      return <Moment format="DD/MMM/YYYY">{record.po_date}</Moment>;
    },
  },
  {
    title: "Supplier",
    dataIndex: "vendor_name",
    key: "vendor_name",
    width: 250,
    align: "center",
  },
  {
    title: "Deliver To",
    dataIndex: "deliver_to",
    key: "deliver_to",
    width: 200,
    align: "center",
  } /*
  {
    title: "Contact Name",
    dataIndex: "contact_name",
    key: "contact_name",
    width: 100,
    align: "center",
  },
  {
    title: "Contact Email",
    dataIndex: "contact_email",
    key: "contact_email",
    width: 100,
    align: "center",
  },
  {
    title: "Contact Phone",
    dataIndex: "contact_phone",
    key: "contact_phone",
    width: 100,
    align: "center",
  },*/,
  {
    title: "Addres L1",
    dataIndex: "shipping_addressL1",
    key: "shipping_addressL1",
    width: 300,
    align: "center",
  },
  {
    title: "Address L2",
    dataIndex: "shipping_addressL2",
    key: "shipping_addressL2",
  },
  {
    title: "City",
    dataIndex: "shipping_address_city",
    key: "shipping_address_city",
    width: 100,
    align: "center",
  },
  {
    title: "State",
    dataIndex: "shipping_address_state",
    key: "shipping_address_state",
    width: 80,
    align: "center",
  },
  {
    title: "Postal Code",
    dataIndex: "shipping_address_postal",
    key: "shipping_address_postal",
    width: 80,
    align: "center",
  },

  {
    title: "Item",
    dataIndex: "name",
    key: "name",
    width: 130,
  },
  {
    title: "Cartons",
    dataIndex: "quantity",
    key: "quantity",
    width: 80,
    align: "right",
  },

  {
    title: "Unit Price($)",
    dataIndex: "unit_price",
    key: "unit_price",
    width: 100,
    align: "right",
  },

  {
    title: "Est. Freight Rate($)",
    dataIndex: "est_freight_rate",
    key: "est_freight_rate",
    width: 100,
    align: "right",
  },
  {
    title: "Shipping Mode",
    dataIndex: "shipping_mode",
    key: "shipping_mode",
    width: 100,
  },

  {
    title: "Total($)",
    dataIndex: "total_buy",
    key: "total_buy",
    width: 100,
    align: "right",
  },
];
