import React from "react";
import { Upload, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import axios from "axios";

// function getBase64(file) {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => resolve(reader.result);
//     reader.onerror = (error) => reject(error);
//   });
// }

// export class QcDetails extends React.Component {
//   state = {
//     previewVisible: false,
//     previewImage: "",
//     previewTitle: "",
//     fileList: [
//       {
//         uid: "-1",
//         name: "image.png",
//         status: "done",
//         url:
//           "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
//       },
//       {
//         uid: "-2",
//         name: "image.png",
//         status: "done",
//         url:
//           "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
//       },
//       {
//         uid: "-3",
//         name: "image.png",
//         status: "done",
//         url:
//           "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
//       },
//       {
//         uid: "-4",
//         name: "image.png",
//         status: "done",
//         url:
//           "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
//       },
//       {
//         uid: "-xxx",
//         percent: 50,
//         name: "image.png",
//         status: "uploading",
//         url:
//           "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
//       },
//       {
//         uid: "-5",
//         name: "image.png",
//         status: "error",
//       },
//     ],
//   };

//   handleCancel = () => this.setState({ previewVisible: false });

//   handlePreview = async (file) => {
//     if (!file.url && !file.preview) {
//       file.preview = await getBase64(file.originFileObj);
//     }

//     this.setState({
//       previewImage: file.url || file.preview,
//       previewVisible: true,
//       previewTitle:
//         file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
//     });
//   };

//   handleChange = ({ fileList }) => this.setState({ fileList });

//   render() {
//     const { previewVisible, previewImage, fileList, previewTitle } = this.state;
//     const uploadButton = (
//       <div>
//         <PlusOutlined />
//         <div style={{ marginTop: 8 }}>Upload</div>
//       </div>
//     );
//     return (
//       <>
//         <Upload
//           action={async (file) => {
//             const formdata = new FormData();
//             formdata.append("uploaded_file", file, file.name);
//             formdata.append("shipment_id", this.props.content[0].shipment_id);
//             const response = await axios.post(
//               "https://localhost:44352/api/qcupload",
//               formdata,
//               {
//                 headers: {
//                   "Content-Type": "application/json",
//                 },
//               }
//             );

//             const data=await response.data;
//             console.log(data);
//           }}
//           //action="https://localhost:44352/api/qcupload"
//           listType="picture-card"
//           fileList={fileList}
//           onPreview={this.handlePreview}
//           onChange={this.handleChange}
//         >
//           {fileList.length >= 8 ? null : uploadButton}
//         </Upload>
//         <Modal
//           visible={previewVisible}
//           title={previewTitle}
//           footer={null}
//           onCancel={this.handleCancel}
//         >
//           <img alt="example" style={{ width: "100%" }} src={previewImage} />
//         </Modal>
//       </>
//     );
//   }
// }

//ReactDOM.render(<PicturesWall />, document.getElementById("container")); // import React from "react";
import { Input, Tabs } from "antd";
const { TabPane } = Tabs;

export default function QcDetails(props) {
  return (
    <div>
      <h3>QC Link:</h3>
      <Input
        defaultValue={props.content[0].qc_link_temp}
        onChange={(e) => {
          props.content[0].qc_link_temp = e.target.value;
        }}
      />
    </div>
  );
}
