import React from "react";

export const OfficeRoles = [
  "Management Company",
  "General Contractor",
  "Architect",
  "Investor/ Owner",
  "Developer",
  "Consultant",
  "Interior Designer",
  "Purchasing Company",
];
export const Phases = [
  "Under Construction",
  "Planning",
  "Pre-Planning",
  "Vision",
];
export const Categories = [
  "New Building",
  "Refurbishment",
  "Extension",
  "Conversion",
];
export const Countries = [
  {
    Country: "Panama",
    Continent: "South America",
  },
  {
    Country: "Bulgaria",
    Continent: "Europe",
  },
  {
    Country: "Faeroe Islands",
    Continent: "Europe",
  },
  {
    Country: "Kenya",
    Continent: "Africa",
  },
  {
    Country: "Ecuador",
    Continent: "South America",
  },
  {
    Country: "Japan",
    Continent: "Asia",
  },
  {
    Country: "Montenegro",
    Continent: "Europe",
  },
  {
    Country: "Ghana",
    Continent: "Africa",
  },
  {
    Country: "Greenland",
    Continent: "North America",
  },
  {
    Country: "Zambia",
    Continent: "Africa",
  },
  {
    Country: "Iran",
    Continent: "Asia",
  },
  {
    Country: "Cayman Islands",
    Continent: "North America",
  },
  {
    Country: "Myanmar",
    Continent: "Asia",
  },
  {
    Country: "Congo",
    Continent: "Africa",
  },
  {
    Country: "Switzerland",
    Continent: "Europe",
  },
  {
    Country: "Mauritius",
    Continent: "Africa",
  },
  {
    Country: "Rwanda",
    Continent: "Africa",
  },
  {
    Country: "Cambodia",
    Continent: "Asia",
  },
  {
    Country: "Poland",
    Continent: "Europe",
  },
  {
    Country: "Seychelles",
    Continent: "Africa",
  },
  {
    Country: "United Kingdom",
    Continent: "Europe",
  },
  {
    Country: "Liberia",
    Continent: "Africa",
  },
  {
    Country: "Belgium",
    Continent: "Europe",
  },
  {
    Country: "Somalia",
    Continent: "Africa",
  },
  {
    Country: "Federal Democratic Republic of Nepal",
    Continent: "Asia",
  },
  {
    Country: "Pakistan",
    Continent: "Asia",
  },
  {
    Country: "Colombia",
    Continent: "South America",
  },
  {
    Country: "Bermuda",
    Continent: "North America",
  },
  {
    Country: "Hong Kong",
    Continent: "Asia",
  },
  {
    Country: "Monaco",
    Continent: "Europe",
  },
  {
    Country: "Turkey",
    Continent: "Asia",
  },
  {
    Country: "Egypt",
    Continent: "Africa",
  },
  {
    Country: "Ukraine",
    Continent: "Europe",
  },
  {
    Country: "Botswana",
    Continent: "Africa",
  },
  {
    Country: "Germany",
    Continent: "Europe",
  },
  {
    Country: "Brazil",
    Continent: "South America",
  },
  {
    Country: "Madagascar",
    Continent: "Africa",
  },
  {
    Country: "Barbados",
    Continent: "North America",
  },
  {
    Country: "Niger",
    Continent: "Africa",
  },
  {
    Country: "Gibraltar",
    Continent: "Europe",
  },
  {
    Country: "Guatemala",
    Continent: "North America",
  },
  {
    Country: "Mozambique",
    Continent: "Africa",
  },
  {
    Country: "Czech Republic",
    Continent: "Europe",
  },
  {
    Country: "Peru",
    Continent: "South America",
  },
  {
    Country: "Uganda",
    Continent: "Africa",
  },
  {
    Country: "Malta",
    Continent: "Europe",
  },
  {
    Country: "Slovenia",
    Continent: "Europe",
  },
  {
    Country: "Vietnam",
    Continent: "Asia",
  },
  {
    Country: "Finland",
    Continent: "Europe",
  },
  {
    Country: "Turks and Caicos Islands",
    Continent: "North America",
  },
  {
    Country: "Timor-Leste",
    Continent: "Asia",
  },
  {
    Country: "Cyprus",
    Continent: "Europe",
  },
  {
    Country: "Grenada",
    Continent: "North America",
  },
  {
    Country: "Saudi Arabia",
    Continent: "Asia",
  },
  {
    Country: "Iceland",
    Continent: "Europe",
  },
  {
    Country: "Romania",
    Continent: "Europe",
  },
  {
    Country: "Bahrain",
    Continent: "Asia",
  },
  {
    Country: "Guyana",
    Continent: "South America",
  },
  {
    Country: "Australia",
    Continent: "Oceania",
  },
  {
    Country: "Nigeria",
    Continent: "Africa",
  },
  {
    Country: "Bhutan",
    Continent: "Asia",
  },
  {
    Country: "New Zealand",
    Continent: "Oceania",
  },
  {
    Country: "United Arab Emirates",
    Continent: "Asia",
  },
  {
    Country: "Kyrgyzstan",
    Continent: "Asia",
  },
  {
    Country: "Oman",
    Continent: "Asia",
  },
  {
    Country: "Réunion",
    Continent: "Africa",
  },
  {
    Country: "Philippines",
    Continent: "Asia",
  },
  {
    Country: "Albania",
    Continent: "Europe",
  },
  {
    Country: "El Salvador",
    Continent: "North America",
  },
  {
    Country: "Dominica",
    Continent: "North America",
  },
  {
    Country: "Jamaica",
    Continent: "North America",
  },
  {
    Country: "Uruguay",
    Continent: "South America",
  },
  {
    Country: "Ireland",
    Continent: "Europe",
  },
  {
    Country: "Greece",
    Continent: "Europe",
  },
  {
    Country: "Maldives",
    Continent: "Asia",
  },
  {
    Country: "Burkina Faso",
    Continent: "Africa",
  },
  {
    Country: "Fiji",
    Continent: "Oceania",
  },
  {
    Country: "India",
    Continent: "Asia",
  },
  {
    Country: "Spain",
    Continent: "Europe",
  },
  {
    Country: "Israel",
    Continent: "Asia",
  },
  {
    Country: "British Virgin Islands",
    Continent: "North America",
  },
  {
    Country: "Bolivia",
    Continent: "South America",
  },
  {
    Country: "Netherlands",
    Continent: "Europe",
  },
  {
    Country: "Jordan",
    Continent: "Asia",
  },
  {
    Country: "Italy",
    Continent: "Europe",
  },
  {
    Country: "Bangladesh",
    Continent: "Asia",
  },
  {
    Country: "Bosnia and Herzegovina",
    Continent: "Europe",
  },
  {
    Country: "Algeria",
    Continent: "Africa",
  },
  {
    Country: "Libya",
    Continent: "Africa",
  },
  {
    Country: "Sri Lanka",
    Continent: "Asia",
  },
  {
    Country: "Dominican Republic",
    Continent: "North America",
  },
  {
    Country: "Sweden",
    Continent: "Europe",
  },
  {
    Country: "Sudan",
    Continent: "Africa",
  },
  {
    Country: "St. Vincent and the Grenadines",
    Continent: "North America",
  },
  {
    Country: "Cuba",
    Continent: "North America",
  },
  {
    Country: "Angola",
    Continent: "Africa",
  },
  {
    Country: "St. Martin",
    Continent: "North America",
  },
  {
    Country: "Russia",
    Continent: "Europe",
  },
  {
    Country: "United States of America",
    Continent: "North America",
  },
  {
    Country: "Zimbabwe",
    Continent: "Africa",
  },
  {
    Country: "Latvia",
    Continent: "Europe",
  },
  {
    Country: "Palau",
    Continent: "Oceania",
  },
  {
    Country: "Democratic Republic of the Congo",
    Continent: "Africa",
  },
  {
    Country: "Azerbaijan",
    Continent: "Asia",
  },
  {
    Country: "Cote d'Ivoire",
    Continent: "Africa",
  },
  {
    Country: "Thailand",
    Continent: "Asia",
  },
  {
    Country: "Curacao",
    Continent: "North America",
  },
  {
    Country: "Morocco",
    Continent: "Africa",
  },
  {
    Country: "Singapore",
    Continent: "Asia",
  },
  {
    Country: "Aruba",
    Continent: "North America",
  },
  {
    Country: "Georgia",
    Continent: "Asia",
  },
  {
    Country: "Slovakia",
    Continent: "Europe",
  },
  {
    Country: "Belize",
    Continent: "South America",
  },
  {
    Country: "Uzbekistan",
    Continent: "Asia",
  },
  {
    Country: "France",
    Continent: "Europe",
  },
  {
    Country: "Haiti",
    Continent: "North America",
  },
  {
    Country: "United States Virgin Islands",
    Continent: "North America",
  },
  {
    Country: "Ethiopia",
    Continent: "Africa",
  },
  {
    Country: "Taiwan",
    Continent: "Asia",
  },
  {
    Country: "Portugal",
    Continent: "Europe",
  },
  {
    Country: "Guinea",
    Continent: "Africa",
  },
  {
    Country: "South Africa",
    Continent: "Africa",
  },
  {
    Country: "Senegal",
    Continent: "Africa",
  },
  {
    Country: "Lithuania",
    Continent: "Europe",
  },
  {
    Country: "Tunisia",
    Continent: "Africa",
  },
  {
    Country: "Kuwait",
    Continent: "Asia",
  },
  {
    Country: "Armenia",
    Continent: "Asia",
  },
  {
    Country: "Costa Rica",
    Continent: "North America",
  },
  {
    Country: "China",
    Continent: "Asia",
  },
  {
    Country: "South Korea",
    Continent: "Asia",
  },
  {
    Country: "Antigua and Barbuda",
    Continent: "South America",
  },
  {
    Country: "Cameroon",
    Continent: "Africa",
  },
  {
    Country: "Paraguay",
    Continent: "South America",
  },
  {
    Country: "Malaysia",
    Continent: "Asia",
  },
  {
    Country: "Benin",
    Continent: "Africa",
  },
  {
    Country: "Estonia",
    Continent: "Europe",
  },
  {
    Country: "Cape Verde",
    Continent: "Africa",
  },
  {
    Country: "Indonesia",
    Continent: "Asia",
  },
  {
    Country: "Bahamas",
    Continent: "North America",
  },
  {
    Country: "Mexico",
    Continent: "North America",
  },
  {
    Country: "St. Lucia",
    Continent: "North America",
  },
  {
    Country: "Iraq",
    Continent: "Asia",
  },
  {
    Country: "Laos",
    Continent: "Asia",
  },
  {
    Country: "St. Kitts and Nevis",
    Continent: "North America",
  },
  {
    Country: "Belarus",
    Continent: "Europe",
  },
  {
    Country: "Chile",
    Continent: "South America",
  },
  {
    Country: "Venezuela",
    Continent: "South America",
  },
  {
    Country: "Austria",
    Continent: "Europe",
  },
  {
    Country: "Argentina",
    Continent: "South America",
  },
  {
    Country: "Croatia",
    Continent: "Europe",
  },
  {
    Country: "Hungary",
    Continent: "Europe",
  },
  {
    Country: "Puerto Rico",
    Continent: "North America",
  },
  {
    Country: "British West Indies",
    Continent: "North America",
  },
  {
    Country: "Luxembourg",
    Continent: "Europe",
  },
  {
    Country: "Kazakhstan",
    Continent: "Asia",
  },
  {
    Country: "United Republic of Tanzania",
    Continent: "Africa",
  },
  {
    Country: "Canada",
    Continent: "North America",
  },
  {
    Country: "Northern Mariana Islands",
    Continent: "Oceania",
  },
  {
    Country: "Serbia",
    Continent: "Europe",
  },
  {
    Country: "Honduras",
    Continent: "South America",
  },
  {
    Country: "Macau",
    Continent: "Asia",
  },
  {
    Country: "Qatar",
    Continent: "Asia",
  },
  {
    Country: "Denmark",
    Continent: "Europe",
  },
  {
    Country: "Norway",
    Continent: "Europe",
  },
];

export const Continents = [
  "Asia",
  "Africa",
  "Europe",
  "North America",
  "Oceania",
  "South America",
];
