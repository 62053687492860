import React from "react";
import logo from "./logo.png";
import "./App.css";
import POUpload from "./Views/POUpload";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Shipments from "./Views/ViewShipments";
//import CreateShipment from "./Views/CreateShipment";
import ViewPurchaseOrders from "./Views/ViewPurchaseOrders";
import SearchScreenMR from "./Views/SearchScreenMR";

import { Menu } from "antd";
import {
  MailOutlined,
  AppstoreOutlined,
  SettingOutlined,
} from "@ant-design/icons";

const POApp = () => {
  return (
    <Router>
      <Menu mode="horizontal" defaultSelectedKeys={["Shipments"]}>
        <Menu.Item key="PO Uplaod" icon={<AppstoreOutlined />}>
          <Link to="/POAPp/upload">Upload PO</Link>
        </Menu.Item>
        <Menu.Item key="Create Shipment" icon={<AppstoreOutlined />}>
          <Link to="/POApp/createShipment">Create Shipment</Link>
        </Menu.Item>
        <Menu.Item key="Shipments" icon={<MailOutlined />}>
          <Link to="/POApp/shipments">Manage Shipments</Link>
        </Menu.Item>
        {/* <Menu.Item key="MarketingApp." icon={<MailOutlined />}>
          <Link to="/MRApp">Marketing App.</Link>
  </Menu.Item>*/}
      </Menu>
      <hr />

      <Switch>
        <Route exact path="/POApp/shipments" component={Shipments}></Route>
        <Route
          path="/POApp/createShipment"
          component={ViewPurchaseOrders}
        ></Route>
        <Route path="/POApp/upload" component={POUpload}></Route>
      </Switch>
    </Router>
  );
};

export default POApp;
