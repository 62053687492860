import React, { useState } from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import "../Assets/login.css";
import { Form, Input, Button, Checkbox, Alert, Spin, Card } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
//  import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import { withRouter, useHistory, Redirect, Link } from "react-router-dom";
import API from "../API/APIUtil";
//import WebLinks from "../../../API/WebLinks";
//import { from } from "rxjs";
//import AuthService from "../../../Service/AuthService";
import AuthService from "../Service/AuthService";
import CookieService from "../Service/CookieService";
import CookieList from "../Service/CookieList";

// const { history } = this.props;

const api = new API();
const expiresAt = 60 * 24;
const LoginPage = () => {
  const history = useHistory();
  const [txtUsername, setUsername] = useState(null);
  const [txtPassword, setPassword] = useState(null);
  const [chkRemember, setRemember] = useState(true);
  const [chkIternalEmployee, setInternalEmployee] = useState(false);
  const [alertVisible, setVisible] = useState(false);
  const [alertMessage, setMessage] = useState("");
  const [alertType, setType] = useState("error");
  const [isloading, setloading] = useState(false);

  const onFinish = (values) => {};
  const showMessage = (message, type) => {
    setMessage(message);
    setType(type);
    setVisible(true);
    setTimeout(hideMessage, 2000);
  };
  const hideMessage = () => {
    setVisible(false);
  };
  const onLogin = async (events) => {
    setTimeout(setloading(true), 2000);

    let isError = false;
    let payload = {
      user_id: txtUsername,
      password: txtPassword,
      internal_user: chkIternalEmployee,
    };

    /*   const result = await Utils.apiCall(
      "POST",
      WebLinks.LoginLink,
      payload,
      false
    );
    */
    api.createEntity({ name: "login" });
    api.endpoints.login
      .create(payload, {
        headers: { "Content-Type": "application/json" },
      })
      .then((result) => {
        let date = new Date();
        date.setTime(date.getTime() + expiresAt * 60 * 1000);
        let options = {
          path: "/",
          expires: date,
        };
        // console.log(res);
        CookieService.set(CookieList.id, result.data.id, options);
        CookieService.set(CookieList.user_name, result.data.user_id, options);
        CookieService.set(
          CookieList.user_token,
          result.data.access_token,
          options
        );
        CookieService.set(
          CookieList.user_first_name,
          result.data.userName,
          options
        ); //set permissions
        CookieService.set(
          CookieList.user_permissions,
          result.data.permittedApps
        );
        CookieService.set(CookieList.user_email, result.data.email, options);
        setTimeout(showMessage("Logged in", "success"), 2000);
        history.push("/");
        /*CookieService.set(
          CookieList.user_permissions,
          result.resultObj.Data.permissions,
          options
        );*/
      })
      .catch((error) => {
        console.log(error);
        showMessage("Something went wrong", "error");
      });

    setloading(false);
    if (isError) {
      //TODO: show error message "something went wrong. try again later." | Logout
    }
    //const result = await Utils.Token();
    //console.log("API RES:", result);
  };

  return (
    <div className="form-div" type="flex" justify="center" align="center">
      <Spin spinning={isloading} tip="Loading...">
        <Card className="card-style" style={{ width: 330 }}>
          <img src="/logo.png" className="login-logo"></img>
          <h1 class="caption-header-style">App Central</h1>
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
              internalEmployee: true,
            }}
            onFinish={onFinish}
          >
            <h3 class="login-header-style">Login</h3>
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please input your Username!",
                },
              ]}
            >
              <Input
                className="input-box-style"
                placeholder="Username"
                onChange={(event) => setUsername(event.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
              ]}
            >
              <Input
                className="input-box-style"
                type="password"
                placeholder="Password"
                onChange={(event) => setPassword(event.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox
                  className="login-form-remember"
                  onChange={(event) => setRemember(!chkRemember)}
                >
                  Remember me
                </Checkbox>
              </Form.Item>
              <Link
                className="login-form-forgot"
                to={{
                  pathname: "/forgotPassword",
                }}
              >
                Forgot password
              </Link>
            </Form.Item>
            <Form.Item>
              <Form.Item
                name="internalEmployee"
                valuePropName="checked"
                noStyle
              >
                <Checkbox
                  className="login-form-remember"
                  onChange={(event) => setInternalEmployee(!chkIternalEmployee)}
                >
                  Internal Employee Login
                </Checkbox>
              </Form.Item>
            </Form.Item>

            <Form.Item>
              <Button
                block
                type="primary"
                htmlType="submit"
                className="login-form-button"
                onClick={onLogin}
              >
                Log In
              </Button>
              <p class="or-register">
                Or <a href="">register now!</a>
              </p>
              {/* <a href="">Provision</a> */}
            </Form.Item>

            {alertVisible ? (
              <Alert
                message={alertMessage}
                type={alertType}
                closable
                showIcon
              />
            ) : null}
          </Form>
        </Card>
      </Spin>
    </div>
  );
};

export default LoginPage;
