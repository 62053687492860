import Cookies from "universal-cookie";

const cookies = new Cookies();
class CookieService {
  get(cookieName) {
    return cookies.get(cookieName);
  }

  set(cookieName, value, options) {
    cookies.set(cookieName, value, options);
  }

  remove(cookieName) {
    cookies.remove(cookieName);
    return true;
  }
  removeList(CookieList) {
    CookieList.map((Cookie) => {
      cookies.remove(Cookie);
    });
    return true;
  }
  removeAllCookies() {
    document.cookie.split(";").map((cookieItem) => {
      document.cookie = cookieItem
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
  }
}
export default new CookieService();
