import React, { useEffect, useState } from "react";
import API from "../API/APIUtil";
import CookieService from "../Service/CookieService";
import CommentsThread from "./CommentsThread";

import {
  Comment,
  Avatar,
  Form,
  Button,
  List,
  Input,
  Typography,
  message,
  Modal,
} from "antd";
import moment from "moment";

const api = new API();

const { TextArea } = Input;
const { Title, Paragraph } = Typography;

const CommentList = ({ comments }) => (
  <List
    dataSource={comments}
    // header={`${comments.length} ${comments.length > 1 ? "replies" : "reply"}`}
    itemLayout="horizontal"
    renderItem={(props) => <Comment {...props} />}
    // pagination={{ pageSize: 5, size: "small" }}
  />
);

const Editor = ({ onChange, onSubmit, submitting, value }) => (
  <>
    <Form.Item>
      <TextArea rows={4} onChange={onChange} value={value} />
    </Form.Item>
    <Form.Item>
      <Button
        htmlType="submit"
        loading={submitting}
        onClick={onSubmit}
        type="primary"
      >
        Add Comment
      </Button>
    </Form.Item>
  </>
);

export default function Comments(props) {
  const [commentsState, setCommentsState] = useState({
    comments: [],
    submitting: false,
    value: "",
  });
  const [prevComments, setprevComments] = useState(false);

  useEffect(() => {
    //get comments for a thread
    api.createEntity({ name: "GetComments" });
    api.endpoints.GetComments.create(
      {
        thread_id: props.thread_id.comments_thread_id,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        console.log(res);
        if (res.data !== null) {
          setCommentsState({
            comments: res.data.map((comment, idx) => {
              return {
                avatar: <Avatar alt="User"></Avatar>,
                author: <Title level={5}>{comment.author}</Title>,
                content: <p>{comment.content}</p>,
                datetime: moment(comment.datetime).format("DD MMM YYYY hh:mm"),
                thread_id: props.thread_id.comments_thread_id,
              };
            }),
          });
        }
      })
      .catch((err) => {
        message.error(
          "Error Occured while fetching comments, Please try again"
        );
      });
  }, []);

  const handleChange = (e) => {
    setCommentsState({ ...commentsState, value: e.target.value });
  };

  const handleSubmit = () => {
    if (!commentsState.value) {
      return;
    }
    setCommentsState({ ...commentsState, submitting: true });

    setTimeout(() => {
      //post call to api

      api.createEntity({ name: "InsertComment" });
      api.endpoints.InsertComment.create(
        {
          author: CookieService.get("userFirstName"),
          content: commentsState.value,
          thread_id: props.thread_id.comments_thread_id,
          datetime: moment().toISOString(),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          //  setData(res.data);
          setCommentsState({
            submitting: false,
            value: "",
            comments: [
              ...commentsState.comments,
              {
                avatar: <Avatar alt="User"></Avatar>,
                author: (
                  <Title level={5}>{CookieService.get("userFirstName")}</Title>
                ),
                content: <p>{commentsState.value}</p>,
                datetime: moment().fromNow(),
                thread_id: props.thread_id.comments_thread_id,
              },
            ],
          });
          console.log(res);
        })
        .catch((err) => {
          message.error("Error while saving comment. Please try again");
        });
    }, 1000);
    console.log(commentsState.comments);
  };

  return (
    <>
      <div style={{ maxHeight: "300px", overflowY: "scroll" }}>
        <CommentList comments={commentsState.comments}></CommentList>
      </div>
      <Comment
        avatar={<Avatar alt="User" />}
        content={
          <Editor
            onChange={handleChange}
            onSubmit={handleSubmit}
            submitting={commentsState.submitting}
            value={commentsState.value}
          />
        }
      />
      <Button
        onClick={() => {
          setprevComments(true);
        }}
      >
        View Prev. Comments
      </Button>
      <Modal
        visible={prevComments}
        onCancel={() => {
          setprevComments(false);
        }}
      >
        <CommentsThread thread_id={props.thread_id}></CommentsThread>
      </Modal>
    </>
  );
}
