import React from "react";

export function CargoShipIcon() {
  return (
    <img
      alt="shipping-mode/SEA"
      style={{ height: "35px", width: "35px" }}
      src={process.env.PUBLIC_URL + "/cargo-ship.png"}
    />
  );
}

export function CargoPlaneIcon() {
  return (
    <img
      alt="shipping-mode/AIR"
      style={{ height: "35px", width: "35px" }}
      src={process.env.PUBLIC_URL + "/cargo-plane.png"}
    />
  );
}
