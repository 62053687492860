import React, { Component } from "react";
import moment from "moment";
import "../App.css";
import CommentsSection from "./Comments";
import {
  Modal,
  Popover,
  Table,
  Checkbox,
  Divider,
  DatePicker,
  Select,
  InputNumber,
  Input,
  Tooltip,
  Badge,
} from "antd";
import Moment from "react-moment";

export default class ViewShipmentDetails extends Component {
  state = {
    selectedRowKeys: [],
    checkedRows: [],
    visible: false,
    Comments: false,
    confirmLoading: false,
    dataRecord: null,
    actual: false,
  };
  getMax = (a, b) => {
    return a > b ? a : b;
  };
  showModal = () => {
    if (this.state.checkedRows.length == 0) {
      alert("Select atleast one line item for shipment");
      return;
    }

    this.setState({
      visible: true,
    });
  };
  handleAddShipment = () => {
    if (this.state.checkedRows.length === 0) {
      alert("Select atleast one line item for shipment");
    } else console.log("clicked", this.state.checkedRows);
  };

  handleRowSelectionChange = (selectedRowKeys, selectedRows) => {
    selectedRows.shipmentId = this.props.id;
    this.setState({ checkedRows: selectedRows }, () => {
      console.log("checked rows:", this.state.checkedRows);
    });
  };

  handleOk = () => {
    this.setState({
      confirmLoading: true,
    });
    setTimeout(() => {
      this.setState({
        comments: false,
        confirmLoading: false,
      });
    }, 2000);
  };

  handleCancel = () => {
    console.log("Clicked cancel button");
    this.setState({
      Comments: false,
      onChange: false,
    });
  };

  handleCommentsClick = () => {
    this.setState({ Comments: true });
  };

  render() {
    const col = [
      {
        title: "PO",
        dataIndex: "po_number",
        key: "po_number",
        fixed: "left",
        width: 100,
        align: "center",
      },
      {
        title: "PO Date",
        dataIndex: "po_date",
        key: "po_date",
        width: 100,
        render: (_, record) => {
          return <Moment format="DD/MMM/YYYY">{record.po_date}</Moment>;
        },
        align: "center",
      },

      {
        title: "Deliver To",
        dataIndex: "deliver_to",
        key: "deliver_to",
        width: 150,
        align: "center",
      },
      {
        title: "Vendor",
        dataIndex: "deliver_to",
        key: "deliver_to",
        width: 150,
        align: "center",
      },
      {
        title: "Item",
        dataIndex: "name",
        key: "name",
        editable: true,
        width: 120,
        align: "center",
      },
      {
        title: "Shipping Details",
        children: [
          {
            title: "Actual freight rate",
            dataIndex: "actual_freight_rate",
            key: "actual_freight_rate",
            editable: true,
            width: 120,
            render: (_, record) => {
              return (
                <InputNumber
                  style={{ width: "70px" }}
                  defaultValue={record.actual_freight_rate}
                  min={0}
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  onChange={(value) => {
                    record.actual_freight_rate = value;
                    record.actual_freight_cost =
                      record.actual_freight_rate *
                      this.getMax(
                        record.volforEachCartonOnQty,
                        record.totalVolWeightOnQtyCm
                      );
                    this.setState({ refresh: true });
                    console.log(value);
                  }}
                />
              );
            },
            align: "center",
          },

          {
            title: "Actual Freight Cost",
            dataIndex: "actual_freight_cost",
            key: "actual_freight_cost",
            editable: true,
            width: 120,
            render: (_, record) => {
              return (
                <InputNumber
                  style={{ width: "70px" }}
                  defaultValue={record.actual_freight_cost}
                  min={0}
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  onChange={(value) => {
                    record.actual_freight_cost = value;
                  }}
                />
              );
            },
            align: "center",
          },
          {
            title: "Est. Freight Cost",
            dataIndex: "est_freight_cost",
            key: "est_freight_cost",
            editable: true,
            width: 120,
            render: (_, record) => {
              return (
                <Tooltip
                  title={`Est. freight rate x MAX(Vol. for each carton, Total Vol. weight on Qty.) = ${
                    this.props.estFreightRate
                  } x ${this.getMax(
                    record.totalGwOnQtyKg,
                    record.totalVolWeightOnQtyCm
                  )}`}
                >
                  <InputNumber
                    readOnly
                    style={{ width: "90px" }}
                    defaultValue={(
                      this.props.estFreightRate *
                      this.getMax(
                        record.totalGwOnQtyKg,
                        record.totalVolWeightOnQtyCm
                      )
                    ).toFixed(3)}
                    min={0}
                    formatter={(value) =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Tooltip>
              );
            },
            align: "center",
          },

          {
            title: "QC Result",
            dataIndex: "qc_result",
            key: "qc_result",
            editable: true,
            width: 130,
            render: (_, record) => {
              const { Option } = Select;
              return (
                <Select
                  defaultValue={record.qc_result}
                  style={{ width: 120 }}
                  onChange={(value) => {
                    record.qc_result = value;
                  }}
                >
                  <Option value="pass">
                    <Badge status="success" text="Pass" />
                  </Option>
                  <Option value="fail">
                    <Badge status="error" text="Fail" />
                  </Option>
                  <Option value="pending">
                    <Badge status="warning" text="Pending" />
                  </Option>
                </Select>
              );
            },
            align: "center",
          },

          {
            title: "Est. Departure Date",
            dataIndex: "est_dep_date",
            key: "est_dep_date",
            editable: true,
            width: 150,
            render: (_, record) => {
              return (
                //<Moment format="DD/MMM/YYYY">{record.est_dep_date}</Moment>;
                <div>
                  <Popover
                    content="Check downstream dates"
                    title="Warning"
                    trigger="click"
                  >
                    <DatePicker
                      format="DD/MMM/YYYY"
                      defaultPickerValue={moment(
                        record.est_dep_date == null ||
                          record.est_dep_date != "0001-01-01T00:00:00"
                          ? new Date()
                          : new Date(record.est_dep_date),
                        "DD/MMM/YYYY"
                      )}
                      defaultValue={
                        record.est_dep_date != null &&
                        record.est_dep_date != "0001-01-01T00:00:00"
                          ? moment(new Date(record.est_dep_date), "DD/MMM/YYYY")
                          : null
                      }
                      onChange={(date) => {
                        record.est_dep_date =
                          date != null ? date.format("DD/MMM/YYYY") : date;
                        //this.setState({ onChange: true });
                      }}
                    ></DatePicker>
                  </Popover>
                </div>
              );
            },
          },
          {
            title: "Est. Port Of Entry Date",
            dataIndex: "est_port_entry_date",
            key: "est_port_entry_date",
            editable: true,
            width: 150,
            render: (_, record) => {
              return (
                <div>
                  <DatePicker
                    format="DD/MMM/YYYY"
                    defaultPickerValue={moment(
                      record.est_port_entry_date == null
                        ? new Date()
                        : new Date(record.est_port_entry_date),
                      "DD/MMM/YYYY"
                    )}
                    defaultValue={
                      record.est_port_entry_date != null &&
                      record.est_port_entry_date != "0001-01-01T00:00:00"
                        ? moment(
                            new Date(record.est_port_entry_date),
                            "DD/MMM/YYYY"
                          )
                        : null
                    }
                    onChange={(date) => {
                      record.est_port_entry_date =
                        date != null ? date.format("DD/MMM/YYYY") : date;
                    }}
                  ></DatePicker>
                </div>
              );
            },
          },
          {
            title: "Quantities",
            children: [
              {
                title: "Ordered",
                dataIndex: "ordered",
                key: "ordered",
                width: 70,
                align: "right",
              },
              {
                title: "Received",
                dataIndex: "received",
                key: "received",
                editable: true,
                width: 90,
                render: (_, record) => {
                  return (
                    <InputNumber
                      style={{ width: "70px" }}
                      size={"small"}
                      defaultValue={record.received}
                      onChange={(value) => {
                        record.received = value;
                      }}
                    />
                  );
                },
              },
              {
                title: "Pending",
                dataIndex: "pending",
                key: "pending",
                editable: true,
                width: 70,
                render: (_, record) => {
                  return (
                    <InputNumber
                      readOnly
                      style={{ width: "50px" }}
                      size={"small"}
                      defaultValue={record.ordered - record.received}
                      onChange={(value) => {
                        record.received = value;
                      }}
                    />
                  );
                },
              },
            ],
          },

          {
            title: "Delivery Service",
            dataIndex: "del_service",
            key: "del_service",
            editable: true,
            width: 100,
            render: (_, record) => {
              return (
                <Input
                  style={{ width: "90px" }}
                  size={"small"}
                  defaultValue={record.del_service}
                  onChange={(e) => {
                    record.del_service = e.target.value;
                  }}
                />
              );
            },
          },
          {
            title: "Tracking#",
            dataIndex: "tracking",
            key: "tracking",
            editable: true,
            width: 180,
            render: (_, record) => {
              return (
                <Input
                  style={{ width: "160px" }}
                  size={"small"}
                  defaultValue={record.tracking}
                  onChange={(e) => {
                    record.tracking = e.target.value;
                  }}
                />
              );
            },
          },

          {
            title: "Comments",
            dataIndex: "comment_temp",
            key: "comment_temp",
            render: (_, record) => {
              return (
                <a
                  onClick={() => {
                    console.log(this.state.dataRecord);
                    this.setState({ Comments: true, dataRecord: record });
                  }}
                >
                  Comments
                </a>
              );
            },
            width: 100,
          },
          {
            title: "Dates",
            children: [
              {
                title: "Est. Customs Clearance",
                dataIndex: "est_customsClearance_date",
                key: "est_customsClearance_date",
                editable: true,
                width: 150,
                render: (_, record) => {
                  return (
                    <div>
                      <Popover
                        content="Check downstream dates"
                        title="Warning"
                        trigger="click"
                      >
                        <DatePicker
                          format="DD/MMM/YYYY"
                          defaultPickerValue={moment(
                            record.est_customsClearance_date == null
                              ? new Date()
                              : new Date(record.est_customsClearance_date),
                            "DD/MMM/YYYY"
                          )}
                          defaultValue={
                            record.est_customsClearance_date !== null &&
                            record.est_customsClearance_date !=
                              "0001-01-01T00:00:00"
                              ? moment(
                                  new Date(record.est_customsClearance_date),
                                  "DD/MMM/YYYY"
                                )
                              : null
                          }
                          onChange={(date) => {
                            record.est_customsClearance_date =
                              date != null ? date.format("DD/MMM/YYYY") : date;
                            //this.setState({ onChange: true });
                          }}
                        ></DatePicker>
                      </Popover>
                    </div>
                  );
                },
              },
              {
                title: "Actual Customs Clearance",
                dataIndex: "actual_customsClearance_date",
                key: "actual_customsClearance_date",
                editable: true,
                width: 150,
                render: (_, record) => {
                  return (
                    //<Moment format="DD/MMM/YYYY">{record.est_dep_date}</Moment>;
                    <div>
                      <Popover
                        content="Check downstream dates"
                        title="Warning"
                        trigger="click"
                      >
                        <DatePicker
                          format="DD/MMM/YYYY"
                          defaultPickerValue={moment(
                            record.est_customsClearance_date == null
                              ? new Date()
                              : new Date(record.est_customsClearance_date),
                            "DD/MMM/YYYY"
                          )}
                          defaultValue={
                            record.actual_customsClearance_date !== null &&
                            record.actual_customsClearance_date !=
                              "0001-01-01T00:00:00"
                              ? moment(
                                  new Date(record.actual_customsClearance_date),
                                  "DD/MMM/YYYY"
                                )
                              : null
                          }
                          onChange={(date) => {
                            record.actual_customsClearance_date =
                              date != null ? date.format("DD/MMM/YYYY") : date;
                            //this.setState({ onChange: true });
                          }}
                        ></DatePicker>
                      </Popover>
                    </div>
                  );
                },
              },
              {
                title: "Est. Del. To Customer",
                dataIndex: "est_del_usWarehouse_date",
                key: "est_del_usWarehouse_date",
                editable: true,
                width: 150,
                render: (_, record) => {
                  return (
                    //<Moment format="DD/MMM/YYYY">{record.est_dep_date}</Moment>;
                    <div>
                      <Popover
                        content="Check downstream dates"
                        title="Warning"
                        trigger="click"
                      >
                        <DatePicker
                          format="DD/MMM/YYYY"
                          defaultPickerValue={moment(
                            record.est_del_usWarehouse_date == null
                              ? new Date()
                              : new Date(record.est_del_usWarehouse_date),
                            "DD/MMM/YYYY"
                          )}
                          defaultValue={
                            record.est_del_usWarehouse_date !== null &&
                            record.est_del_usWarehouse_date !=
                              "0001-01-01T00:00:00"
                              ? moment(
                                  new Date(record.est_del_usWarehouse_date),
                                  "DD/MMM/YYYY"
                                )
                              : null
                          }
                          onChange={(date) => {
                            record.est_del_usWarehouse_date =
                              date != null ? date.format("DD/MMM/YYYY") : date;

                            //this.setState({ onChange: true });
                          }}
                        ></DatePicker>
                      </Popover>
                    </div>
                  );
                },
              },
              {
                title: "Actual Del. Date To Customer",
                dataIndex: "actual_del_usWarehouse_date",
                key: "actual_del_usWarehouse_date",
                editable: true,
                width: 150,
                render: (_, record) => {
                  return (
                    //<Moment format="DD/MMM/YYYY">{record.est_dep_date}</Moment>;
                    <div>
                      <Popover
                        content="Check downstream dates"
                        title="Warning"
                        trigger="click"
                      >
                        <DatePicker
                          format="DD/MMM/YYYY"
                          defaultPickerValue={moment(
                            record.est_customsClearance_date == null
                              ? new Date()
                              : new Date(record.est_customsClearance_date),
                            "DD/MMM/YYYY"
                          )}
                          defaultValue={
                            record.actual_del_usWarehouse_date !== null &&
                            record.actual_del_usWarehouse_date !=
                              "0001-01-01T00:00:00"
                              ? moment(
                                  new Date(record.actual_del_usWarehouse_date),
                                  "DD/MMM/YYYY"
                                )
                              : null
                          }
                          onChange={(date) => {
                            record.actual_del_usWarehouse_date =
                              date != null ? date.format("DD/MMM/YYYY") : date;
                            //this.setState({ onChange: true });
                          }}
                        ></DatePicker>
                      </Popover>
                    </div>
                  );
                },
              },
              {
                title: "Delivered",
                dataIndex: "status_id",
                key: "status_id",

                width: 90,
                align: "center",
                render: (_, record) => {
                  return (
                    <Checkbox
                      onChange={(val) => {
                        record.status_id = val.target.checked;
                      }}
                    ></Checkbox>
                  );
                },
              },
            ],
          },
        ],
      },
    ];
    const { visible, confirmLoading, Comments } = this.state;

    const expandableObj1 = {
      expandedRowRender: (record, index, indent, expanded) => {
        if (!expanded) return;
        //console.log(record, expanded);
        const columns = [
          {
            title: " Carton Size(Cm)",
            children: [
              {
                title: "Length",
                dataIndex: "length_cm",
                key: "length_cm",
                align: "right",
              },
              {
                title: "width",
                dataIndex: "width_cm",
                key: "width_cm",
                align: "right",
              },
              {
                title: "Height",
                dataIndex: "height_cm",
                key: "height_cm",
                align: "right",
              },
            ],
          },
          {
            title: " Carton Size(In)",
            children: [
              {
                title: "Length",
                dataIndex: "length_in",
                key: "length_in",
                align: "right",
              },
              {
                title: "Width",
                dataIndex: "width_in",
                key: "width_in",
                align: "right",
              },
              {
                title: "Height",
                dataIndex: "height_in",
                key: "height_in",
                align: "right",
              },
            ],
          },
          {
            title: "G.W.(Kg)",
            dataIndex: "gw_kg",
            key: "gw_kg",
            align: "right",
          },
          {
            title: "G.W.(Lbs)",
            dataIndex: "gw_lbs",
            key: "gw_lbs",
            align: "right",
          },

          {
            title: "Total G.W.(Kg) on Qty",
            dataIndex: "totalGwOnQtyKg",
            key: "totalGwOnQtyKg",
            align: "right",
          },
          {
            title: "Total G.W.(Lbs) on Qty",
            dataIndex: "totalGwOnQtyLbs",
            key: "totalGwOnQtyLbs",
            align: "right",
          },
          {
            title: "Vol. Per Carton(Cm^3)",
            dataIndex: "volforEachCartonOnQty",
            key: "volforEachCartonOnQty",
            align: "right",
          },
          {
            title: "Total Vol.(Cm^3)",
            dataIndex: "totalVolWeightOnQtyCm",
            key: "totalVolWeightOnQtyCm",
            align: "right",
          },
          {
            title: "Case Price($)",
            dataIndex: "unit_case_price",
            key: "unit_case_price",
            width: 100,
            align: "right",
          },
          {
            title: "Case Pack",
            dataIndex: "case_pack",
            key: "case_pack",
            width: 100,
            align: "right",
          },
        ];

        const data = [];

        data.push({
          key: record.key,
          case_pack: record.case_pack,
          quantity: record.quantity,
          unit_price: record.unit_price,
          unit_case_price: record.unit_case_price,
          length_cm: record.length_cm,
          width_cm: record.width_cm,
          height_cm: record.height_cm,
          length_in: record.length_in,
          width_in: record.width_in,
          height_in: record.height_in,
          gw_kg: record.gw_kg,
          gw_lbs: record.gw_lbs,
          totalGwOnQtyKg: record.totalGwOnQtyKg,
          totalGwOnQtyLbs: record.totalGwOnQtyLbs,
          volforEachCartonOnQty: record.volforEachCartonOnQty,
          totalVolWeightOnQtyCm: record.totalVolWeightOnQtyCm,
        });

        return (
          <div>
            <Table
              columns={columns}
              dataSource={data}
              pagination={false}
              scroll={{ y: 500 }}
              size="small"
              bordered
            />
            <Divider></Divider>
          </div>
        );
      },
    };
    return (
      <div>
        <Table
          columns={col}
          dataSource={this.props.content}
          scroll={{ x: "calc(1000px + 50%)" }}
          bordered
          pagination={false}
          expandable={expandableObj1}
          onExpand={this.handleRowExpand}
          size="small"
        ></Table>

        <Popover
          content="Select line items to be added to sub-shipment"
          title="Help"
          trigger="hover"
        ></Popover>
        {/* <Modal
          title="Shipment"
          visible={visible}
          onOk={this.handleOk}
          confirmLoading={confirmLoading}
          onCancel={this.handleCancel}
          width={1200}
          centered
        ></Modal> */}
        <Modal
          destroyOnClose
          title={
            this.state.dataRecord != null
              ? `Comment For ${this.state.dataRecord.po_number}-${this.state.dataRecord.name} `
              : ""
          }
          visible={Comments}
          onOk={this.handleOk}
          confirmLoading={confirmLoading}
          footer={null}
          onCancel={this.handleCancel}
          width={800}
          centered
        >
          <CommentsSection thread_id={this.state.dataRecord}></CommentsSection>
          {/* <CommentsThread thread_id={this.state.dataRecord}></CommentsThread> */}
        </Modal>
      </div>
    );
  }
}
