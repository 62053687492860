import React from "react";
import ReactDOM from "react-dom";

import "antd/dist/antd.css";
import "../Assets/default.css";
import { Layout, Menu, Avatar } from "antd";
import { SettingOutlined, LogoutOutlined } from "@ant-design/icons";
import CookieService from "../Service/CookieService";
import CookieList from "../Service/CookieList";
import AuthService from "../Service/AuthService";
import { useHistory, Redirect } from "react-router-dom";
//import Logout from "../Views/Common/Logout"

const { Header } = Layout;
const { SubMenu } = Menu;
const TopNavBar = () => {
  const history = useHistory();
  return (
    <Header>
      <img
        style={{ height: "10%", width: "10%" }}
        src={process.env.PUBLIC_URL + "/logo.png"}
        alt="LOGO"
        onClick={() => {
          history.push("/");
        }}
      />
      <h1 class="caption">App Central</h1>

      <Menu theme="dark" mode="horizontal" style={{ float: "right" }}>
        <Menu.Item key="1" disabled={true}>
          {CookieService.get(CookieList.user_name)}
        </Menu.Item>

        <Menu.Item
          key="3"
          onClick={(e) => {
            AuthService.logout();
            history.push("/login");
          }}
        >
          <LogoutOutlined />
        </Menu.Item>
      </Menu>
    </Header>
  );
};

export default TopNavBar;
