import React from "react";
import Moment from "react-moment";

export const poTableCol = [
  {
    title: "PO",
    dataIndex: "poId",
    key: "poId",
    width: 100,
    fixed: "left",
  },
  {
    title: "PO Date",
    dataIndex: "poDate",
    key: "poDate",
    render: (_, record) => {
      return <Moment format="DD/MMM/YYYY">{record.po_date}</Moment>;
    },
    width: 100,
  },
  {
    title: "Supplier",
    dataIndex: "supplier",
    key: "supplier",
    width: 100,
  },
  {
    title: "Deliver To",
    dataIndex: "deliverTo",
    key: "deliverTo",
    width: 150,
  },
  {
    title: "Contact Name",
    dataIndex: "contactName",
    key: "contactName",
    width: 100,
  },
  {
    title: "Contact Email",
    dataIndex: "contactEmail",
    key: "contactEmail",
    width: 100,
  },
  {
    title: "Contact Phone",
    dataIndex: "contactPhone",
    key: "contactPhone",
    width: 100,
  },
  {
    title: "Addres L1",
    dataIndex: "shippingAddressLine1",
    key: "shippingAddressLine1",
    width: 200,
  },
  /* {
    title: "Address L2",
    dataIndex: "shippingAddressLine2",
    key: "shippingAddressLine2",
  },
  {
    title: "Address L3",
    dataIndex: "shippingAddressLine3",
    key: "shippingAddressLine3",
  },*/
  {
    title: "City",
    dataIndex: "shippingAddressCity",
    key: "shippingAddressCity",
    width: 100,
  },
  {
    title: "State",
    dataIndex: "shippingAddressState",
    key: "shippingAddressLineState",
    width: 100,
  },
  {
    title: "Postal Code",
    dataIndex: "shippingAddressPostalCode",
    key: "shippingAddressPostalCode",
    width: 100,
  },
  {
    title: "Purchase Order Details",
    children: [
      {
        title: "Item",
        dataIndex: "productName",
        key: "productName",
        width: 100,
      },
      {
        title: "Case Pack",
        dataIndex: "casePack",
        key: "casePack",
        width: 100,
        align: "right",
      },
      {
        title: "Cartons",
        dataIndex: "quantity",
        key: "quantity",
        width: 100,
        align: "right",
      },
      {
        title: "Unit Price($)",
        dataIndex: "exwUnitBuy",
        key: "exwUnitBuy",
        width: 100,
        align: "right",
      },
      {
        title: "Est. Freight Rate($)",
        dataIndex: "estFreightRate",
        key: "estFreightRate",
        width: 100,
        align: "right",
      },
      {
        title: "Case Price($)",
        dataIndex: "exwCaseBuy",
        key: "exwCaseBuy",
        width: 100,
        align: "right",
      },
    ],
  },

  {
    title: "Total($)",
    dataIndex: "totalBuy",
    key: "totalBuy",
    width: 100,
    align: "right",
  },

  {
    title: "Shipping Mode",
    dataIndex: "shippingMode",
    key: "shippingMode",
    width: 100,
  },
];
