import React from "react";
import { Route, Redirect } from "react-router-dom";

import Cookie from "../Service/CookieService";
import CookieList from "../Service/CookieList";
import AuthService from "../Service/AuthService";

/**
 *
 * @param {Object} Route parameters
 * @description Route parameters must contain layout, component and other props. Layout has to be the master oage.
 */
export default ({
  layout,
  component,
  permission,
  isSubPermission,
  subPermission,
  ...rest
}) => {
  let token = Cookie.get(CookieList.user_token);

  if (token == null) {
    return <Redirect to="/login" />;
  }

  if (permission != null) {
    if (!AuthService.isPermitted(permission, subPermission, isSubPermission)) {
      return <Redirect to="/" />;
    }
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        React.createElement(
          layout,
          { ...props, ...rest },
          React.createElement(component, { ...props, ...rest })
        )
      }
    />
  );
};
