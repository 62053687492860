import React, { useEffect, useState } from "react";
import { Empty, Card, Row, Col, Typography, Form, Tooltip, Switch } from "antd";
import API from "../API/APIUtil";

const api = new API();
const { Title, Link, Text } = Typography;
const checkForEmptyString = (str) => {
  return str === "" ? "--" : str;
};

const checkBeforeConcatenation = (str1, str2, str3, typeStr) => {
  let seperator;
  if (typeStr === "location") seperator = ",";
  else seperator = " ";
  let temp = "";
  if (str1 !== "") temp += str1;
  if (str2 !== "") temp += seperator + str2;
  if (str3 !== "") temp += seperator + str3;

  return temp === "" ? "--" : temp;
};
const isDefaultChecked = (arr, id) => {
  if (arr.includes(id)) {
    return true;
  } else {
    return false;
  }
};

export default function OfficeDetails(props) {
  //const { selectedRowKeys = [] } = props.selectedRowKeysPropMethod();

  const [data, setData] = useState([]);

  const [form] = Form.useForm();
  const [form2] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    form2.resetFields();
    api.createEntity({ name: "GetOfficeDataForProject" });
    api.endpoints.GetOfficeDataForProject.create(
      {
        projectId: props.projectId,
        officeRole: props.role,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        setData(res.data);
        console.log(res);
      })
      .catch((err) => {});
  }, [props.role]);

  return (
    <div style={{ height: "410px", overflowY: "scroll" }}>
      {data.length > 0 ? (
        data.map((selectedRowTable, idx) => {
          const onSwitchChanged = (checked) => {
            if (checked) {
              props.selection(
                [...props.getSelectedRowKeysPropMethod(), selectedRowTable.ID],
                [...props.getSelectedRowsPropMethod(), selectedRowTable]
              );
            } else {
              let arr = props.getSelectedRowKeysPropMethod();
              let arrRwos = props.getSelectedRowsPropMethod();

              let index = arr.indexOf(selectedRowTable.ID);
              let indexRows = arrRwos
                .map((element) => {
                  return element.ID;
                })
                .indexOf(selectedRowTable.ID);

              if (index > -1) {
                arr.splice(index, 1);
                arrRwos.splice(indexRows, 1);
                props.selection([...arr], [...arrRwos]);
              }
            }
          };
          return (
            <Card>
              <Typography>
                <Title level={4}>
                  {selectedRowTable !== null
                    ? selectedRowTable.officeCompany +
                      (props.role === "other"
                        ? " (" + selectedRowTable.officeRole + ") "
                        : "")
                    : ""}
                </Title>
                <a href={selectedRowTable.officeWebsite} target="_blank">
                  Company Website
                </a>
                <br></br>
                <br></br>

                <Row>
                  <Col span={12}>
                    <Form
                      form={form}
                      labelCol={{
                        span: 4,
                      }}
                      preserve={false}
                      initialValues={{
                        officeLocation: checkBeforeConcatenation(
                          selectedRowTable.officeCity,
                          selectedRowTable.officeState,
                          selectedRowTable.officeCountry,
                          "location"
                        ),
                        officeEmail: checkForEmptyString(
                          selectedRowTable.officeEmail
                        ),
                        officePhone: checkForEmptyString(
                          selectedRowTable.officePhone
                        ),
                        officeMobile: checkForEmptyString(
                          selectedRowTable.officeMobile
                        ),
                      }}
                      wrapperCol={{
                        span: 14,
                      }}
                      layout="horizontal"
                      size={"small"}
                    >
                      <Form.Item name="officeLocation" label="Location">
                        {
                          /* <Input
                          readOnly
                          defaultValue={checkBeforeConcatenation(
                            selectedRowTable.officeCity,
                            selectedRowTable.officeState,
                            selectedRowTable.officeCountry,
                            "location"
                          )}
                        /> */
                          <Title level={5}>
                            {checkBeforeConcatenation(
                              selectedRowTable.officeCity,
                              selectedRowTable.officeState,
                              selectedRowTable.officeCountry,
                              "location"
                            )}
                          </Title>
                        }
                      </Form.Item>
                      <br></br>
                      <br></br>

                      <Form.Item name="officeEmail" label="Email">
                        {
                          /* <Input
                          defaultValue={checkForEmptyString(
                            selectedRowTable.officeEmail
                          )}
                          readOnly
                        /> */
                          <Title level={5}>
                            {checkForEmptyString(selectedRowTable.officeEmail)}
                          </Title>
                        }
                      </Form.Item>
                      <Form.Item name="officePhone" label="Phone">
                        {
                          /* <Input
                          defaultValue={checkForEmptyString(
                            selectedRowTable.officePhone
                          )}
                          readOnly
                        /> */
                          <Text strong>
                            {checkForEmptyString(selectedRowTable.officePhone)}
                          </Text>
                        }
                      </Form.Item>
                      <Form.Item name="officeMobile" label="Mobile">
                        {
                          /* <Input
                          defaultValue={checkForEmptyString(
                            selectedRowTable.officeMobile
                          )}
                          readOnly
                        /> */
                          <Title level={5}>
                            {checkForEmptyString(selectedRowTable.officeMobile)}{" "}
                          </Title>
                        }
                      </Form.Item>
                    </Form>
                  </Col>
                  <Col span={12}>
                    <Form
                      form={form2}
                      labelCol={{
                        span: 4,
                      }}
                      // initialValues={{
                      //   officeContact: checkBeforeConcatenation(
                      //     selectedRowTable.officeContactTitle,
                      //     selectedRowTable.officeContactFirstName,
                      //     selectedRowTable.officeContactLastName,
                      //     "contact"
                      //   ),
                      //   officeContactPosition: checkForEmptyString(
                      //     selectedRowTable.officeContactPosition
                      //   ),
                      //   officeContactPhone: checkForEmptyString(
                      //     selectedRowTable.officeContactPhone
                      //   ),
                      //   officeContactEmail: checkForEmptyString(
                      //     selectedRowTable.officeContactEmail
                      //   ),
                      //   officeContactMobile: checkForEmptyString(
                      //     selectedRowTable.officeContactMobile
                      //   ),
                      // }}
                      wrapperCol={{
                        span: 14,
                      }}
                      layout="horizontal"
                      size={"small"}
                    >
                      <Tooltip title="Title-First Name-Last Name"></Tooltip>
                      <Form.Item
                        name="officeContact"
                        label="Contact"
                        name="size"
                      >
                        {
                          /* <Input
                          readOnly
                          defaultValue={checkBeforeConcatenation(
                            selectedRowTable.officeContactTitle,
                            selectedRowTable.officeContactFirstName,
                            selectedRowTable.officeContactLastName,
                            "contact"
                          )}
                        /> */
                          <Title level={5}>
                            {checkBeforeConcatenation(
                              selectedRowTable.officeContactTitle,
                              selectedRowTable.officeContactFirstName,
                              selectedRowTable.officeContactLastName,
                              "contact"
                            )}
                          </Title>
                        }
                      </Form.Item>

                      <Form.Item name="offcieContactPosition" label="Position">
                        {
                          /* <Input
                          readOnly
                          defaultValue={checkForEmptyString(
                            selectedRowTable.officeContactPosition
                          )
                        }
                        /> */
                          <Title level={5}>
                            {checkForEmptyString(
                              selectedRowTable.officeContactPosition
                            )}
                          </Title>
                        }
                      </Form.Item>
                      <Form.Item name="offcieContactEmail" label="Email">
                        <Title level={5}>
                          {checkForEmptyString(
                            selectedRowTable.officeContactEmail
                          )}
                        </Title>
                      </Form.Item>
                      <Form.Item name="offcieContactPhone" label="Phone">
                        <Title level={5}>
                          {checkForEmptyString(
                            selectedRowTable.officeContactPhone
                          )}
                        </Title>
                      </Form.Item>
                      <Form.Item name="offcieContactMobile" label="Mobile">
                        <Title level={5}>
                          {checkForEmptyString(
                            selectedRowTable.officeContactMobile
                          )}
                        </Title>
                      </Form.Item>
                      <Form.Item>
                        <Text>{"Select for download "}</Text>
                        <Switch
                          size="default"
                          checked={isDefaultChecked(
                            props.getSelectedRowKeysPropMethod(),
                            selectedRowTable.ID
                          )}
                          onChange={onSwitchChanged}
                        ></Switch>
                      </Form.Item>
                    </Form>
                  </Col>
                </Row>
              </Typography>
            </Card>
          );
        })
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
      )}
    </div>
  );
}
