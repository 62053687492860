import React, { useState } from "react";
import { Table, Modal, Collapse, Typography, Button, Radio } from "antd";
import ReactExport from "react-export-excel";
import MasterDataDetails from "./MasterDataDetails";
import { DownloadOutlined } from "@ant-design/icons";

export default function SearchResults(props) {
  const [visible, setVisible] = useState(false);
  const [selectedRowTable, setSelectedRow] = useState(null);
  const [selectedRowkeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const { Text } = Typography;
  const { Panel } = Collapse;

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const setSelectedRowsAndKeysTest = (record, selected, selectedRowsTemp) => {
    console.log(record, selected);
    if (selected) {
      setSelectedRows([...selectedRows, record]);
      setSelectedRowKeys([...selectedRowkeys, record.ID]);
    } else {
      //check the index of de=selected element in both arrays and remove that item

      let indexRows = selectedRows
        .map((element) => {
          return element.ID;
        })
        .indexOf(record.ID);
      let indexKeys = selectedRowkeys.indexOf(record.ID);
      if (indexKeys > -1 && indexRows > -1) {
        let arrKeys = getSelectedRowKeys();
        arrKeys.splice(indexKeys, 1);
        let arrRows = getSelectedRows();
        arrRows.splice(indexRows, 1);
        setSelectedRows([...arrRows]);
        setSelectedRowKeys([...arrKeys]);

        console.log(indexRows, indexKeys);
      }
    }
    // console.log(record, selected, selectedRows);
  };
  const setSelectedRowsAndKeys = (selectedRowKeysTable, selectedRowsTemp) => {
    //

    setSelectedRows(selectedRowsTemp);

    setSelectedRowKeys(selectedRowKeysTable);

    //
  };
  const setSelectedRowsAndKeysScreen2 = (
    selectedRowKeysTable,
    selectedRowsTemp
  ) => {
    setSelectedRows(selectedRowsTemp);
    setSelectedRowKeys(selectedRowKeysTable);
  };
  const getSelectedRowKeys = () => {
    return selectedRowkeys;
  };
  const getSelectedRows = () => {
    return selectedRows;
  };

  const getSelectedProjectDetails = (selectedRow) => {
    if (selectedRow == null || selectedRow === undefined) return null;
    return props.dataSource.filter((element) => {
      return element.projectId === selectedRow.projectId;
    });
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const columns = [
    {
      title: "Project",
      dataIndex: "projectName",
      align: "center",
      sorter: (a, b) => a.projectName.localeCompare(b.projectName),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Hard Brand/Soft Brand",
      dataIndex: "hardBrand",
      render: (_, record) => {
        return (
          <Text>
            {record.hardBrand +
              (record.softBrands === "" ? "" : "/" + record.softBrands)}
          </Text>
        );
      },
      align: "center",
      sorter: (a, b) => a.hardBrand.localeCompare(b.hardBrand),
      sortDirections: ["descend", "ascend"],
    },
    //{ title: "Soft Brand ", dataIndex: "softBrands" },
    {
      title: "Phase",
      dataIndex: "phase",
      align: "center",
      sorter: (a, b) => a.phase.localeCompare(b.phase),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Category",
      dataIndex: "category",
      align: "center",
      sorter: (a, b) => a.category.localeCompare(b.category),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Stars",
      dataIndex: "stars",
      align: "center",
      sorter: (a, b) => parseInt(a.stars) - parseInt(b.stars),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Rooms",
      dataIndex: "rooms",
      align: "center",
      sorter: (a, b) => parseInt(a.rooms) - parseInt(b.rooms),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Opening Date",
      dataIndex: "openingDate",
      align: "center",
      sorter: (a, b) => a.openingDate.localeCompare(b.openingDate),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "City",
      dataIndex: "city",
      align: "center",
      sorter: (a, b) => a.city.localeCompare(b.city),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Country",
      dataIndex: "country",
      align: "center",
      sorter: (a, b) => a.country.localeCompare(b.country),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Role",
      dataIndex: "officeRole",
      align: "center",
      sorter: (a, b) => a.officeRole.localeCompare(b.officeRole),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Office Company",
      dataIndex: "officeCompany",
      align: "center",
      sorter: (a, b) => a.officeCompany.localeCompare(b.officeCompany),
      sortDirections: ["descend", "ascend"],
    },
  ];
  const onChange = (e) => {
    e.stopPropagation();
    console.log("radio checked", e.target.value);
  };

  return (
    <div>
      {selectedRows.length > 0 ? (
        <Collapse>
          <Panel
            header="Selected Rows"
            extra={
              <>
                <Radio.Group onChange={onChange}>
                  <Radio value={1}>Download For HubSpot</Radio>
                  <Radio value={2}>Download For Other Purposes</Radio>
                </Radio.Group>
                <ExcelFile
                  element={
                    <Button
                      type="primary"
                      shape="round"
                      icon={<DownloadOutlined />}
                      size="middle"
                    >
                      Download
                    </Button>
                  }
                >
                  <ExcelSheet data={selectedRows} name="Project Details">
                    <ExcelColumn label="Project Name" value="projectName" />
                    <ExcelColumn label="Hard Brand" value="hardBrand" />
                    <ExcelColumn label="Soft Brand" value="softBrand" />
                    <ExcelColumn label="Phase" value="phase" />
                    <ExcelColumn label="Status" value="status" />
                    <ExcelColumn label="Category" value="category" />
                    <ExcelColumn label="Stars" value="stars" />
                    <ExcelColumn label="Rooms" value="rooms" />
                    <ExcelColumn label="TBI (In MIL USD)" value="tbi" />
                    <ExcelColumn label="Opening Date" value="openingDate" />

                    <ExcelColumn label="City" value="city" />
                    <ExcelColumn label="State" value="state" />
                    <ExcelColumn label="Country" value="country" />
                    <ExcelColumn label="Continent" value="continent" />
                    <ExcelColumn label="Description" value="description" />
                    <ExcelColumn label="Office ID" value="officeId" />
                    <ExcelColumn label="Office Company" value="officeCompany" />
                    <ExcelColumn label="Office Name" value="officeName" />
                    <ExcelColumn label="Office Role" value="officeRole" />
                    <ExcelColumn label="Office City" value="officeCity" />
                    <ExcelColumn label="Office State" value="officeState" />
                    <ExcelColumn label="Office Country" value="officeCountry" />
                    <ExcelColumn label="Office Website" value="officeWebsite" />
                    <ExcelColumn label="Office Email" value="officeEmail" />
                    <ExcelColumn label="Office Phone" value="officePhone" />
                    <ExcelColumn
                      label="Office Contact ID"
                      value="officeContactId"
                    />
                    <ExcelColumn
                      label="Office Contact First Name"
                      value="officeContactFirstName"
                    />
                    <ExcelColumn
                      label="Office Contact Last Name"
                      value="officeContactLastName"
                    />
                    <ExcelColumn
                      label="Office Contact Position"
                      value="officeContactPosition"
                    />
                    <ExcelColumn
                      label="Office Contact Company"
                      value="officeContactCompany"
                    />
                    <ExcelColumn
                      label="Office Contact Email"
                      value="officeContactEmail"
                    />
                    <ExcelColumn
                      label="Office Contact Phone"
                      value="officeContactPhone"
                    />
                    <ExcelColumn
                      label="Office Contact Mobile"
                      value="officeContactMobile"
                    />
                  </ExcelSheet>
                </ExcelFile>
              </>
            }
          >
            <Table
              dataSource={selectedRows}
              columns={columns}
              size="small"
              bordered
              pagination="false"
            ></Table>
          </Panel>
        </Collapse>
      ) : (
        <></>
      )}
      <h1>{`Rows: ${props.dataSource.length}`}</h1>
      <Table
        rowSelection={{
          selectedRowKeys: selectedRowkeys,
          onSelect: setSelectedRowsAndKeysTest,
          onChange: (selectedKeys, selectedLines) => {
            //FOR DE-SELECT ALL
            if (selectedKeys.length === 0) {
              let newSelectedRowKeys = selectedRowkeys.filter(
                (x) =>
                  !props.dataSource
                    .map((element) => {
                      return element.ID;
                    })
                    .includes(x)
              );
              let newSelectedRows = selectedRows.filter((element) => {
                return newSelectedRowKeys.includes(element.ID);
              });

              setSelectedRowKeys(newSelectedRowKeys);
              setSelectedRows(newSelectedRows);
              return;
            }

            //FOR SELECT-ALL
            var unionKeys = [...new Set([...selectedKeys, ...selectedRowkeys])];
            var unionRows = [...new Set([...selectedLines, ...selectedRows])];
            setSelectedRows(unionRows);
            setSelectedRowKeys(unionKeys);
          },
          selections: [
            Table.SELECTION_ALL,
            {
              key: "odd",
              text: "Unselect All Data",
              onSelect: (changableRowKeys) => {
                setSelectedRows([]);
                setSelectedRowKeys([]);
              },
            },
          ],
          // columnTitle: (
          //   <img
          //     alt="hubspot/download"
          //     style={{ height: "35px", width: "35px" }}
          //     src={process.env.PUBLIC_URL + "/hubspot-logo.png"}
          //   />
          // ),
        }}
        size="small"
        bordered
        columns={columns}
        dataSource={props.dataSource}
        onRow={(record, rowIndex) => {
          return {
            onDoubleClick: (event) => {
              setVisible(true);
              console.log(record);
              setSelectedRow(record);
            }, // double click row
          };
        }}
      ></Table>

      <Modal
        //title="Record Details"
        destroyOnClose
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={"1700px"}
        style={{ top: 20, textAlign: "center" }}
        bodyStyle={{ height: "auto", padding: "5px" }}
        footer={[]}
      >
        <MasterDataDetails
          setSelectedRows={setSelectedRowsAndKeysScreen2}
          selectedRowkeysProp={getSelectedRowKeys}
          selectedRowsProp={getSelectedRows}
          selectedRowTable={selectedRowTable}
          SelectedProjectDetails={getSelectedProjectDetails(selectedRowTable)}
        ></MasterDataDetails>
      </Modal>
    </div>
  );
}
