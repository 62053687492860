import { Comment, Form, Button, List, Input } from "antd";
import React from "react";
import API from "../API/APIUtil";
import axios from "axios";

const api = new API();
const { TextArea } = Input;

const CommentList = ({ comments }) => (
  <List
    dataSource={comments}
    header={`${comments.length} ${comments.length > 1 ? "replies" : "reply"}`}
    itemLayout="horizontal"
    renderItem={(props) => <Comment {...props} />}
  />
);

const Editor = ({ onChange, onSubmit, submitting, value }) => (
  <div>
    <Form.Item>
      <TextArea rows={4} onChange={onChange} value={value} />
    </Form.Item>
    <Form.Item>
      <Button
        htmlType="submit"
        loading={submitting}
        onClick={onSubmit}
        type="primary"
      >
        Add Comment
      </Button>
    </Form.Item>
  </div>
);
let data = [];
export default class App extends React.Component {
  state = {
    comments: [],
    submitting: false,
    value: "",
  };

  handleChange = (e) => {
    this.props.thread_id.comment_temp = e.target.value;
  };

  render() {
    const { comments, submitting, value, author } = this.state;

    return (
      <div>
        <TextArea
          rows={15}
          onChange={this.handleChange}
          defaultValue={this.props.thread_id.comment_temp}
          //value={this.props.thread_id.comment_temp}
        />
      </div>
    );
  }
}
